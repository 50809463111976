import classNames from 'classnames';
import { useState } from 'react';

import styles from './filters.module.css'
import {ReactComponent as FilterIcon} from '../../assets/icons/filter.svg'

export const Filters = ({items, chosen, ...props}) => {

    const [open, setOpen] = useState(false)
    
    return (
        <div className={styles['filter-button-container']}>
            <button className={classNames(styles['filter-button'], open ? styles['filter-button-active'] : '')} onClick={() => {console.log(open);setOpen(prev => !prev)}}>
                <FilterIcon stroke={open ? 'var(--color-content-highlight)' : 'var(--color-icon-stroke)'} />
            </button>
            {
                open &&
                (
                    <div className={styles['filter-dropdown']}>
                        {
                            items.map((item, index) => (
                                <div className={classNames(styles['filter-dropdown-item'], index === 0 ? styles['filter-dropdown-item-first'] : '')}>
                                    <input type="checkbox" checked={chosen === item.value} onChange={item.onChange}/>
                                    {item.caption}                    
                                </div>
                            ))
                        }
                    </div>

                )
            }
        </div>
    );
}
