// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-sidebar-item_sidebar-item__l\\+oqv {
    min-height: 100%;
    font-size: 10px;
    font-weight: 450;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.main-sidebar-item_active__7y8ob {
    color: var(--color-sidebar-typography-active)
}

.main-sidebar-item_disabled__-Zyqa {
    color: var(--color-sidebar-typography-disabled)
}

@media screen and (max-width: 1000px) {

    .main-sidebar-item_sidebar-item__l\\+oqv {
        min-height: 100%;
        width: 100%;
        font-size: 14px;
        font-weight: 450;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        display: flex;
        flex-direction: row;
        align-items:center;
        gap: 15px;
        justify-content: flex-start;
        text-transform: uppercase;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/main-sidebar-item/main-sidebar-item.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI;AACJ;;AAEA;IACI;AACJ;;AAEA;;IAEI;QACI,gBAAgB;QAChB,WAAW;QACX,eAAe;QACf,gBAAgB;QAChB,iBAAiB;QACjB,mBAAmB;QACnB,gBAAgB;QAChB,aAAa;QACb,mBAAmB;QACnB,kBAAkB;QAClB,SAAS;QACT,2BAA2B;QAC3B,yBAAyB;IAC7B;AACJ","sourcesContent":[".sidebar-item {\n    min-height: 100%;\n    font-size: 10px;\n    font-weight: 450;\n    line-height: 24px;\n    letter-spacing: 0px;\n    text-align: left;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.active {\n    color: var(--color-sidebar-typography-active)\n}\n\n.disabled {\n    color: var(--color-sidebar-typography-disabled)\n}\n\n@media screen and (max-width: 1000px) {\n\n    .sidebar-item {\n        min-height: 100%;\n        width: 100%;\n        font-size: 14px;\n        font-weight: 450;\n        line-height: 24px;\n        letter-spacing: 0px;\n        text-align: left;\n        display: flex;\n        flex-direction: row;\n        align-items:center;\n        gap: 15px;\n        justify-content: flex-start;\n        text-transform: uppercase;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar-item": `main-sidebar-item_sidebar-item__l+oqv`,
	"active": `main-sidebar-item_active__7y8ob`,
	"disabled": `main-sidebar-item_disabled__-Zyqa`
};
export default ___CSS_LOADER_EXPORT___;
