// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-list_dropdown__41swo {
    position: absolute;
    width: 100%;
    z-index: 30;
    background-color: var(--color-content);
    border-radius: 5px;
    overflow: hidden;
    right: 0
}

.dropdown-list_dropdown-item__rONbu {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-size: 16px;
    font-weight: 400;
    padding-left: 15px;
    border-top: 1px solid var(--color-inactive-filter);
    color: var(--color-secondary-typography)
}

.dropdown-list_dropdown-item-first__jf92e {
    border-top: none
}`, "",{"version":3,"sources":["webpack://./src/components/dropdown-list/dropdown-list.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,sCAAsC;IACtC,kBAAkB;IAClB,gBAAgB;IAChB;AACJ;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,SAAS;IACT,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,kDAAkD;IAClD;AACJ;;AAEA;IACI;AACJ","sourcesContent":[".dropdown {\n    position: absolute;\n    width: 100%;\n    z-index: 30;\n    background-color: var(--color-content);\n    border-radius: 5px;\n    overflow: hidden;\n    right: 0\n}\n\n.dropdown-item {\n    height: 48px;\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    gap: 10px;\n    font-size: 16px;\n    font-weight: 400;\n    padding-left: 15px;\n    border-top: 1px solid var(--color-inactive-filter);\n    color: var(--color-secondary-typography)\n}\n\n.dropdown-item-first {\n    border-top: none\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `dropdown-list_dropdown__41swo`,
	"dropdown-item": `dropdown-list_dropdown-item__rONbu`,
	"dropdown-item-first": `dropdown-list_dropdown-item-first__jf92e`
};
export default ___CSS_LOADER_EXPORT___;
