import { useRef } from 'react';
import { useSelector } from 'react-redux';
import Head from 'next/head';
import { useParams, useNavigate } from 'react-router-dom';
import { Button as CustomButton } from '../../components/button/button';
import { TextField } from '../../components/textfield/textfield';
import { MainSidebar } from '../../components/main-sidebar/main-sidebar';
import { ContentSidebar } from '../../components/content-sidebar/content-sidebar';
import { ContentItem } from '../../components/content-item/content-item';

import { Search } from '../../components/search/search';
import { Filters } from '../../components/filters/filters';

import ContentSideNav from '../../components/content-side-nav/content-side-nav';
import CameraListItem from '../../components/content-list-item/content-list-item';
import serverService from '../../services/servers.service'
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import {useTranslation} from "react-i18next";
import { MobileHeader } from '../../components/mobile-header/mobile-header';

import { ReactComponent as BackIcon } from '../../assets/icons/left.svg'

import editIcon from '../../assets/icons/edit.svg'

import styles from './servers.module.css'


const Archives = () => {
  const {t} = useTranslation("common")
  const navigate = useNavigate()
  const currentServerId = useParams().serverId

  const currentServerData = () => {
    if(currentServerId) {
      return servers.find(server => String(server.id) === currentServerId)
    }
    return {}
  }

  const [formOpen, setFormOpen] = useState(false);
  const handleOpen = () => setFormOpen(true);
  const handleClose = () => setFormOpen(false);

  //const servers = useSelector((state) => state.servers.servers);
  const servers = [
    {
      "id":0,
      "company_id":0,
      "kol_vo":1,
      "kol_vo_dop":0,
      "link":"https://github.com/",
      "login":"login",
      "params":"params",
      "comment":"главный",
      "name":"server 1",
      "password":"123456"
    }
  ]

  const screen = useSelector((state) => state.screen.screen);

  const [urlChangeDisabled, setUrlChangeDisabled] = useState(true)
  const [serverUrl, setServerUrl] = useState('')

  const [value, setValue] = useState(0);

  const [serverTab, setServerTab] = useState('info')
  const [showSidebar, setShowSidebar] = useState(true)
  const [showMainSidebar, setShowMainSidebar] = useState(true)

  const [formFields, setFormFields] = useState(false)

  const updateServerUrl = (data) => {
    
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleValidSubmit = async () => {
    const data = formFields
    try {
      const result = await serverService.createServer(data);
      console.log(result)
      if (result.data) {
        handleClose()
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  }

  const sidebarMainMenu = useRef(null)
  const [openSidebarMainMenu, setOpenSidebarMainMenu] = useState(screen.width > 1000 ? true : false);
  const [desktopOpenMainSidebarMenu, setDesktopOpenMainSidebarMenu] = useState(true)

  const [searchText, setSearchText] = useState('')

  const [filter, setFilter] = useState('all')

  const filters = [
    {
      caption: t('cameras.filter.all'),
      value: 'all',
      onChange: () => {setFilter('all')}
    },
    {
      caption: t('cameras.filter.online'),
      value: 'online',
      onChange: () => {setFilter('online')}
    },
    {
      caption: t('cameras.filter.offline'),
      value: 'offline',
      onChange: () => {setFilter('offline')}
    },
  ]

  const searchFilter = (string, substring) => {
    if (substring !== '') {
      return string.includes(substring)
    }
    return true
  }

  useEffect(() => {
    console.log(servers)
    serverService.getServers()
  }, [])

  return (
    <>
    <div ref={sidebarMainMenu}>
      <MainSidebar show={screen.width < 1000 ? openSidebarMainMenu : desktopOpenMainSidebarMenu} active={'/archive'} onClose={() => {setOpenSidebarMainMenu(false)}}/>
    </div>
    <div className={styles['mainWrapper']}>
      {
          showSidebar &&
          (
            <ContentSidebar heading={t('archives.archives')} mobileSidebarOpen={() => {setOpenSidebarMainMenu(true)}} isButtonEnabled={false} onClose={() => {setShowSidebar(prev => !prev)}}>
            </ContentSidebar>
          )
      }
      <div className={styles['rightBlock']}>
          
      </div>
    </div>
  </>
);
}



export default Archives;
