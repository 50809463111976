import './App.css';
import AppRouter from './app.router';
import { Toaster } from 'react-hot-toast';
import { MainSidebar } from './components/main-sidebar/main-sidebar'
import { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';

import screenService from './services/screen.service'


function App() {

  const setSreenDimensions = screenService.setSreenDimensions

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    setSreenDimensions({width: window.innerWidth, height: window.innerHeight})
    
    const handleWindowResize = () => {
      console.log(screenService)
      setSreenDimensions({width: window.innerWidth, height: window.innerHeight})
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
      <div className="content">
        <AppRouter />
      </div>
  );
}

export default App;
