import authService from './auth.service.js'
import { setScenarios, addScenario, removeScenario, updateSingleScenario } from '../features/scenarios/scenarioSlice.js';
import { store } from "../store.js";

import http from "../utils/http-client.js";

const getAuthUser = authService.getAuthUser

const getScenarios = () => {
    const data = {
        "limit":100,
        "offset":0
    }
    return http.post('/regulation/get-list', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            console.log(parsed)
            store.dispatch(setScenarios(parsed.data.list))
            return parsed.data.list;
        }]
    }).catch((err) => {});
}

const getScenario = (data) => {
    return http.post('/regulation/get', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result).data;
            return parsed;
        }]
    }).catch((err) => {});
}

const createScenario = (data) => {
    const user = getAuthUser()
    return http.post('/regulation/create', {...data}, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            console.log(parsed.data)
            store.dispatch(addScenario(parsed.data))
            return parsed;
        }]
    });
}

const updateScenario = (data) => {
    return http.post('/regulation/update', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            console.log(parsed)
            store.dispatch(updateSingleScenario(parsed.data))
            return parsed;
        }]
    });
}

const deleteScenario = (data) => {
    return http.post('/regulation/delete', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            store.dispatch(removeScenario(parsed.data))
            return parsed;
        }]
    });
}

const methods = {
    getScenarios,
    getScenario,
    createScenario,
    updateScenario,
    deleteScenario,
}

export default methods;
