import React from 'react';

import classNames from 'classnames';

import styles from './content-item.module.css';

export const ContentItem = ({ item, index, onClick, active, icon, mainIcon }) => {
    return (
        <div
            key={index}
            onClick={onClick}
            className={classNames(styles['leftBlockListItem'], active ? styles['activeLeftBlockListItem'] : '')}
        >

            {active &&
                <div className={styles['activeLeftBlockListItemBorder']}></div>
            }

            <div className={styles['leftBlockListItemTop']}>
                <div className={styles['leftBlockListItemTitle']}>{mainIcon && <img src={mainIcon} alt=""/>}{item.name}</div>
                {item.notification &&
                    <div className={styles['notification']}>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.16678 12.2501H5.83345M10.5001 4.66675C10.5001 3.73849 10.1314 2.84825 9.47499 2.19187C8.81861 1.5355 7.92837 1.16675 7.00011 1.16675C6.07186 1.16675 5.18162 1.5355 4.52524 2.19187C3.86886 2.84825 3.50011 3.73849 3.50011 4.66675C3.50011 6.46936 3.04539 7.70356 2.53742 8.51991C2.10894 9.20851 1.8947 9.55281 1.90255 9.64886C1.91125 9.75521 1.93378 9.79576 2.01948 9.85933C2.09688 9.91675 2.44579 9.91675 3.14361 9.91675H10.8566C11.5544 9.91675 11.9033 9.91675 11.9807 9.85933C12.0664 9.79576 12.089 9.75521 12.0977 9.64886C12.1055 9.55281 11.8913 9.20851 11.4628 8.51991C10.9548 7.70356 10.5001 6.46936 10.5001 4.66675Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span className={styles['notificationCount']}>1</span>
                    </div>
                }
            </div>

            {
                item.id &&
                (
                    <div className={styles['leftBlockListItemBottom']}>
                        <img src={icon} alt="" className={styles['leftBlockListItemBottomCamera']}/>
                        <span className={styles['leftBlockListItemBottomCameraText']}>{item.id}</span>
                    </div>
                )
            }

        </div>
    )
}
