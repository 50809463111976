import { useState } from 'react'

import styles from './textfield.module.css'

export const TextField = ({type, fieldName, labelText, placeholder, onChange, value, ...props}) => {

  const [fieldType, setFieldType] = useState(type)

  console.log(fieldType)

  const showPassword = () => {
    if (fieldType === 'text') {
      setFieldType('password')
    } else {
      setFieldType('text')
    }
  }

  return (
    <fieldset>
      <label htmlFor={fieldName}>{labelText}</label>
      <input
      type={fieldType}
      value
      placeholder
      onChange
      {...props}
      />

      {
        type === 'password' && <span onClick={showPassword} className={styles["field-icon"]}>LMAO</span>
      }
    </fieldset>
  );
}
