import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Views from './pages/Views/Views';

import AuthGuard from './guards/auth.guard';

import LoginComponent from "./components/login.component";
import Cameras from './pages/cameras/cameras'
import Servers from './pages/servers/servers'
import Settings from './pages/settings/settings';
import Events from './pages/events/events';
import Archives from './pages/archives/archives';
import Automation from './pages/automation/automation';

const AppRouter = () => {
    return (
        <Routes>
            <Route exact path='/' element={<LoginComponent />} />
            <Route element={<AuthGuard />}>
                <Route path='/cameras' element={<Cameras />} />
                <Route path='/cameras/:cameraId' element={<Cameras />} />
                <Route path='/servers' element={<Servers />} />
                <Route path='/servers/:serverId' element={<Servers />} />
                <Route path='/settings' element={<Settings />} />
                <Route path='/settings/:settingId' element={<Settings />} />
                <Route path='/events' element={<Events />} />
                <Route path='/events/:optionId' element={<Events />} />
                <Route path='/archive' element={<Archives />} />
                <Route path='/automation' element={<Automation />} />
                <Route path='/automation/:scenarioId' element={<Automation />} />
            </Route>
             <Route path="/views" element={<Views />} />
        </Routes>
    )
}

export default AppRouter;
