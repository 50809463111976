import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MainSidebar } from "../../components/main-sidebar/main-sidebar";
import '../../assets/css/view.css';
import addImage from '../../assets/image/add.png';
import {ReactComponent as CameraImage} from '../../assets/icons/cameras.svg';
import cameraImage from '../../assets/image/camera.png';
import defaultImage from '../../assets/image/default.png';
import defaultTwoImage from '../../assets/image/default2.png';
import AddCameraPopup from './AddCameraPopup';
import AddViewPopup from './AddViewPopup';
import viewService from '../../services/views.service'
import cameraService from '../../services/cameras.service'
import { MenuTabs } from '../../components/menu-tabs/menu-tabs';
import { useTranslation } from 'react-i18next';
import GridLayout from "react-grid-layout";
import { ContentSidebar } from '../../components/content-sidebar/content-sidebar';
import { ReactComponent as BackIcon } from '../../assets/icons/left.svg'
import { MobileHeader } from '../../components/mobile-header/mobile-header';
import { VideoStream } from '../../components/video-stream/video-stream';


// import {Appartment} from "./appartment/AddAppartment";
function Views() {

    const views = useSelector((state) => state.viewsSlice.views);
    const cameras = useSelector((state) => state.cameras.cameras);

    const viewCameras = (viewId) => {
        console.log(views)
        const viewItem = views.find(view => view.id === viewId)
        return cameras.filter(camera => JSON.parse(viewItem?.list || '[]').includes(camera.id))
    }

    const {t} = useTranslation("common")
    const moreActionsRef = useRef(null);


    const [viewTabList, setViewTabList] = useState([
        {id: 1,image: defaultTwoImage, title: 'Кухня',  status: 1, pin :false},
        {id: 2,image: defaultTwoImage, title: 'Зона1', status: 2, pin :false},
        {id: 3,image: defaultTwoImage, title: 'Зона2', status: 3, pin :false},
        {id: 4,image: defaultImage,  title: 'Зона3', status: 3, pin :false},
        {id: 5,image: defaultTwoImage,  title: 'Зона4', status: 3, pin :false},
        {id: 6,image: defaultTwoImage,  title: 'Зона5', status: 1, pin :false},
    ]);

    const [activeViewItem, setActiveViewItem] = useState([]);
    const [activeTab, setActiveTab] = useState(1);
    const [openMoreAction, setOpenMoreAction] = useState(null);

    const tabs = [
        {
          caption: t('views.view'),
          value: 1,
          icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path stroke={activeTab === 1 ? "var(--color-content-highlight)" : "var(--color-icon-stroke)" } d="M14.6663 5.95417C14.6663 5.55029 14.6663 5.34835 14.5865 5.25484C14.5172 5.1737 14.4132 5.13065 14.3069 5.13902C14.1843 5.14867 14.0415 5.29146 13.7559 5.57704L11.333 7.99992L13.7559 10.4228C14.0415 10.7084 14.1843 10.8512 14.3069 10.8608C14.4132 10.8692 14.5172 10.8261 14.5865 10.745C14.6663 10.6515 14.6663 10.4495 14.6663 10.0457V5.95417Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path stroke={activeTab === 1 ? "var(--color-content-highlight)" : "var(--color-icon-stroke)" } d="M1.33301 6.53325C1.33301 5.41315 1.33301 4.85309 1.55099 4.42527C1.74274 4.04895 2.0487 3.74299 2.42503 3.55124C2.85285 3.33325 3.4129 3.33325 4.53301 3.33325H8.13301C9.25311 3.33325 9.81317 3.33325 10.241 3.55124C10.6173 3.74299 10.9233 4.04895 11.115 4.42527C11.333 4.85309 11.333 5.41315 11.333 6.53325V9.46659C11.333 10.5867 11.333 11.1467 11.115 11.5746C10.9233 11.9509 10.6173 12.2569 10.241 12.4486C9.81317 12.6666 9.25311 12.6666 8.13301 12.6666H4.53301C3.4129 12.6666 2.85285 12.6666 2.42503 12.4486C2.0487 12.2569 1.74274 11.9509 1.55099 11.5746C1.33301 11.1467 1.33301 10.5867 1.33301 9.46659V6.53325Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>,
          onClick: () => {setActiveTab(1)}
        },
    ]

    const screen = useSelector((state) => state.screen.screen);

    const sidebarMainMenu = useRef(null)
    const [showSidebar, setShowSidebar] = useState(true)
    const [openSidebarMainMenu, setOpenSidebarMainMenu] = useState(screen.width > 1000 ? true : false);
    const [desktopOpenMainSidebarMenu, setDesktopOpenMainSidebarMenu] = useState(true)

    const [isVisibleCameraPopup, setIsVisibleCameraPopup] = useState(false);
    const [isVisibleAddViewPopup, setIsVisibleAddViewPopup] = useState(false);
    const [pinned, setPinned] = useState(-1)

    const deleteCamera = async (cameraId) => {
        const cameraIndex = JSON.parse(activeViewItem.list).indexOf(cameraId)
        const cameraIds = JSON.parse(activeViewItem.list)
        cameraIds.splice(cameraIndex, 1)
        const newView = {...activeViewItem, list: JSON.stringify(cameraIds)}
        viewService.updateView(newView)
    }


    useEffect(() => {
        viewService.getViews()
    }, [])

    useEffect(() => {
        console.log(views, 'useEffect views' )
        setActiveViewItem(views[0])
    }, [views])

    useEffect(() => {
        console.log("CHECK CAMERAS", cameras)
        cameraService.getCameras()
    }, [activeViewItem])

    useEffect(() => {
        console.log('____')
        console.log(viewCameras(activeViewItem))
        console.log(cameras)
    }, [cameras])

    const closeCameraPopup = async () => {
        setIsVisibleCameraPopup(false);
    }

    const closeAddViewPopup = async () => {
        setIsVisibleAddViewPopup(false);
    }

    // Обработчик клика в любом месте сайта
    const handleClickOutside = (event) => {
        if (event.target.classList.contains('viewTabItemHoverBlockRightChild') === false && moreActionsRef.current && !moreActionsRef.current.contains(event.target)) {
            setOpenMoreAction(null);
        }
    };


    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            // Удаление обработчика при размонтировании компонента
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    const checkViewList = async (item) => {
        setActiveViewItem(item);
    }

    const ViewListItem = ({ item, index, onClick, ...props }) => {
        return (
            <div
                key={index}
                onClick={() => {checkViewList(item); onClick()}}
                className={`leftBlockListItem ${item?.id == activeViewItem?.id ? 'activeLeftBlockListItem' : ''}`}
                {...props}
            >

                {item?.id == activeViewItem?.id &&
                    <div className="activeLeftBlockListItemBorder"></div>
                }

                <div className='leftBlockListItemTop'>
                    <span className='leftBlockListItemTitle'>{item?.name}</span>
                    {item?.notification &&
                        <div className='notification'>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.16678 12.2501H5.83345M10.5001 4.66675C10.5001 3.73849 10.1314 2.84825 9.47499 2.19187C8.81861 1.5355 7.92837 1.16675 7.00011 1.16675C6.07186 1.16675 5.18162 1.5355 4.52524 2.19187C3.86886 2.84825 3.50011 3.73849 3.50011 4.66675C3.50011 6.46936 3.04539 7.70356 2.53742 8.51991C2.10894 9.20851 1.8947 9.55281 1.90255 9.64886C1.91125 9.75521 1.93378 9.79576 2.01948 9.85933C2.09688 9.91675 2.44579 9.91675 3.14361 9.91675H10.8566C11.5544 9.91675 11.9033 9.91675 11.9807 9.85933C12.0664 9.79576 12.089 9.75521 12.0977 9.64886C12.1055 9.55281 11.8913 9.20851 11.4628 8.51991C10.9548 7.70356 10.5001 6.46936 10.5001 4.66675Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                            <span className='notificationCount'>1</span>
                        </div>
                    }
                </div>

                <div className='leftBlockListItemBottom'>
                    <img className='leftBlockListItemBottomCamera' src={cameraImage} alt=""/>
                    <span className='leftBlockListItemBottomCameraText'>{item?.id}</span>
                </div>

            </div>
        )
    }


    const CameraStatus = ({item}) => {
        let color = ''
        switch (item?.status) {
            case 1:
                color = '#28C76F';
                break;
            case 2:
                color = '#FF9F43';
                break;
            case 3:
                color = '#E73751';
                break;
            default:
                color = '#28C76F';
        }
        return (
            <div className='cameraStatus' style={{background: color}}></div>
        )
    }


    const pinCamera = (item) => {
        if(pinned !== item.id) {
            setPinned(item.id)
        } else {
            setPinned(-1)
        }
    };

    const ViewTab = () => {

        return (
            <div className={'activeViewTabWrapper'}>
                {viewCameras(activeViewItem?.id).sort((a, b) => (b.id === pinned ? 1 : a.id === pinned ? -1 : 0)).map((item, index)=>{
                    return (
                        <div key={index} className={'viewTabItem'} style={pinned === item.id ? {width:'636px', height:'358px', maxWidth: '100%'} : {}}>
                            <div className='viewTabItemImgWrapper'>
                                <VideoStream className='viewTabItemImg' key={item.id} webSocketId={String(item.id)}/>
                                <div className='viewTabItemHoverBlock' >

                                    <div className='viewTabItemHoverBlockRound'>
                                        <div
                                            className='viewTabItemHoverBlockLeft'
                                            onClick={()=>{
                                                pinCamera(item)
                                            }}
                                        >
                                            {pinned === item.id ?

                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17.9208 12.4005L16.351 10.4383C16.2211 10.2758 16.1561 10.1946 16.1099 10.1042C16.0689 10.0239 16.039 9.93852 16.0208 9.85026C16.0004 9.75078 16.0004 9.64677 16.0004 9.43875V7.30813C16.0004 7.19301 16.0004 7.13544 16.0069 7.07868C16.0127 7.02825 16.0223 6.97833 16.0357 6.92937C16.0507 6.87424 16.0721 6.8208 16.1149 6.71391L17.1227 4.19423C17.4168 3.45914 17.5638 3.09159 17.5025 2.79655C17.4489 2.53853 17.2956 2.31211 17.0759 2.1665C16.8247 2 16.4289 2 15.6372 2H8.36368C7.57197 2 7.17611 2 6.92494 2.1665M12.0004 15L12.0004 22M15 15H7.32943C6.26373 15 5.73088 15 5.4572 14.7815C5.21919 14.5916 5.08073 14.3035 5.08106 13.9989C5.08143 13.6487 5.4143 13.2327 6.08004 12.4005L7.64982 10.4383C7.77977 10.2758 7.84474 10.1946 7.89095 10.1042C7.93194 10.0239 7.9619 9.93852 7.98001 9.85026C8.00043 9.75078 8.00043 9.64677 8.00043 9.43875V8" stroke="#5E5873" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M2 2L22 22" stroke="#5E5873" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                                :
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.0004 15L12.0004 22M8.00043 7.30813V9.43875C8.00043 9.64677 8.00043 9.75078 7.98001 9.85026C7.9619 9.93852 7.93194 10.0239 7.89095 10.1042C7.84474 10.1946 7.77977 10.2758 7.64982 10.4383L6.08004 12.4005C5.4143 13.2327 5.08143 13.6487 5.08106 13.9989C5.08073 14.3035 5.21919 14.5916 5.4572 14.7815C5.73088 15 6.26373 15 7.32943 15H16.6714C17.7371 15 18.27 15 18.5437 14.7815C18.7817 14.5916 18.9201 14.3035 18.9198 13.9989C18.9194 13.6487 18.5866 13.2327 17.9208 12.4005L16.351 10.4383C16.2211 10.2758 16.1561 10.1946 16.1099 10.1042C16.0689 10.0239 16.039 9.93852 16.0208 9.85026C16.0004 9.75078 16.0004 9.64677 16.0004 9.43875V7.30813C16.0004 7.19301 16.0004 7.13544 16.0069 7.07868C16.0127 7.02825 16.0223 6.97833 16.0357 6.92937C16.0507 6.87424 16.0721 6.8208 16.1149 6.71391L17.1227 4.19423C17.4168 3.45914 17.5638 3.09159 17.5025 2.79655C17.4489 2.53853 17.2956 2.31211 17.0759 2.1665C16.8247 2 16.4289 2 15.6372 2H8.36368C7.57197 2 7.17611 2 6.92494 2.1665C6.70529 2.31211 6.55199 2.53853 6.49838 2.79655C6.43707 3.09159 6.58408 3.45914 6.87812 4.19423L7.88599 6.71391C7.92875 6.8208 7.95013 6.87424 7.96517 6.92937C7.97853 6.97833 7.98814 7.02825 7.99392 7.07868C8.00043 7.13544 8.00043 7.19301 8.00043 7.30813Z" stroke="#5E5873" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            }

                                        </div>
                                        <div
                                            className='viewTabItemHoverBlockRight'
                                            onClick={()=>{openMoreAction === item?.id ? setOpenMoreAction(null) : setOpenMoreAction(item?.id)}}
                                        >
                                            <svg className='viewTabItemHoverBlockRightChild' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path className='viewTabItemHoverBlockRightChild' d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#5E5873" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path className='viewTabItemHoverBlockRightChild' d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#5E5873" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path className='viewTabItemHoverBlockRightChild' d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#5E5873" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>

                                        {openMoreAction == item?.id &&
                                            <div className="viewTabItemHoverMoreActions" ref={moreActionsRef}>
                                                <div className="viewTabItemHoverMoreActionsItem" onClick={() => {deleteCamera(item.id)}}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M16 6V5.2C16 4.0799 16 3.51984 15.782 3.09202C15.5903 2.71569 15.2843 2.40973 14.908 2.21799C14.4802 2 13.9201 2 12.8 2H11.2C10.0799 2 9.51984 2 9.09202 2.21799C8.71569 2.40973 8.40973 2.71569 8.21799 3.09202C8 3.51984 8 4.0799 8 5.2V6M10 11.5V16.5M14 11.5V16.5M3 6H21M19 6V17.2C19 18.8802 19 19.7202 18.673 20.362C18.3854 20.9265 17.9265 21.3854 17.362 21.673C16.7202 22 15.8802 22 14.2 22H9.8C8.11984 22 7.27976 22 6.63803 21.673C6.07354 21.3854 5.6146 20.9265 5.32698 20.362C5 19.7202 5 18.8802 5 17.2V6" stroke="#5E5873" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                    <span className='viewTabItemHoverMoreActionsItemText'>Удалить</span>
                                                </div>
                                            </div>
                                        }


                                    </div>
                                </div>

                            </div>

                            <div className='cameraNameWrapper'>
                                <CameraStatus item={item}/>
                                <span className='viewTabItemBottomLeftTitle'> {item.title}</span>
                            </div>

                        </div>
                    )
                })}

            </div>
        )
    }

    const ApartmentTab = () => {
        return (
            <div className={'activeApartmentTabWrapper'}>

                <div className='activeApartmentTabTop'>

                    {/*<Appartment/>*/}

                </div>
                <div className='activeApartmentTabBottom'>

                </div>
            </div>
        )
    }


    return (
      <>
        <div ref={sidebarMainMenu}>
            <MainSidebar show={screen.width < 1000 ? openSidebarMainMenu : desktopOpenMainSidebarMenu} active={'/views'} onClose={() => {setOpenSidebarMainMenu(false)}}/>
        </div>
        <div className='mainWrapper'>
            {
                showSidebar &&
                (
                    <ContentSidebar mobileSidebarOpen={() => {setOpenSidebarMainMenu(true)}} heading={t('views.views')} isButtonEnabled={true} onButtonClick={()=>{setIsVisibleAddViewPopup(true)}}>
                        {views.map((item, index) => {
                            return  <ViewListItem key={index} item={item} index={index} onClick={() => { if (screen.width < 1000) setShowSidebar(false);}} />
                        })}
                    </ContentSidebar>
                )
            }

            <div className='rightBlock'>
                {
                    screen.width < 1000 &&
                    (
                    <MobileHeader heading={activeViewItem?.name} leftIcon={<BackIcon />} leftOnclick={() => {setShowSidebar(prev => !prev)}}/>
                    )
                }
                
                <div className="content-container">

                        <div className='view-heading'>
                            {
                                screen.width > 1000 &&
                                <span className="rightBlockTitle">{activeViewItem?.name}</span>
                            }

                            { activeTab == 1 ?
                                viewCameras(activeViewItem?.id).length < 6 ?
                                <button onClick={()=>{setIsVisibleCameraPopup(true)}} className="rightBlockAddCameraBtn">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 3.75V14.25M3.75 9H14.25" stroke="#5E5873" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    {t('views.addCamera')}
                                </button>
                                : null
                                
                                :
                                <button className="rightBlockAddCameraBtn">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 3.75V14.25M3.75 9H14.25" stroke="#5E5873" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    {t('views.addPlan')}
                                </button>
                            }
                        </div>




                    <div className="tabsMainWrapper">
                        <MenuTabs tabs={tabs} active={activeTab} />
                    </div>


                    { activeTab == 1 ?
                    !showSidebar || screen.width > 1000 ?
                    <ViewTab/> : null
                    :
                    <ApartmentTab/>
                    }

            </div>


              </div>

          </div>

          {isVisibleCameraPopup && <AddCameraPopup closeCameraPopup={closeCameraPopup} activeViewItem={activeViewItem} handleClose={() => {setIsVisibleCameraPopup(false)}}/>}
          {isVisibleAddViewPopup && <AddViewPopup closeCameraPopup={closeAddViewPopup} />}

      </>
  );
}

export default Views;
