import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    cameras: [],
}

export const cameraSlice = createSlice({
    name: 'cameras',
    initialState,
    reducers: {
        setCameras: (state, action) => {
            state.cameras = action.payload
        },
        addCamera: (state, action) => {
            state.cameras.push(action.payload)
        },
        updateSingleCamera: (state, action) => {
            const cameraToUpdateIndex = state.cameras.findIndex(camera => camera.id === action.payload.id)
            state.cameras[cameraToUpdateIndex] = action.payload
        },
        removeCamera: (state, action) => {
            const cameraToDeleteIndex = state.cameras.findIndex(camera => camera.id === action.payload.id)
            state.cameras.splice(cameraToDeleteIndex, 1)
        },
        deleteCameras: (state) => {
            state.cameras = []
        },
    },
})

export const { setCameras, addCamera, updateSingleCamera, removeCamera, deleteCameras } = cameraSlice.actions

export default cameraSlice.reducer