import { useRef } from 'react';
import { useSelector } from 'react-redux';
import Head from 'next/head';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Button as CustomButton } from '../../components/button/button';
import { TextField } from '../../components/textfield/textfield';
import { MainSidebar } from '../../components/main-sidebar/main-sidebar';
import { ContentSidebar } from '../../components/content-sidebar/content-sidebar';
import { ContentItem } from '../../components/content-item/content-item';

import { Search } from '../../components/search/search';
import { Filters } from '../../components/filters/filters';

import ContentSideNav from '../../components/content-side-nav/content-side-nav';
import CameraListItem from '../../components/content-list-item/content-list-item';
import serverService from '../../services/servers.service'
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import {useTranslation} from "react-i18next";
import { MobileHeader } from '../../components/mobile-header/mobile-header';
import AddServerPopup from './AddServerPopup';

import { ReactComponent as BackIcon } from '../../assets/icons/left.svg'
import serverIcon from '../../assets/icons/server.svg'

import editIcon from '../../assets/icons/edit.svg'

import styles from './servers.module.css'


const Servers = () => {
  const {t} = useTranslation("common")
  const navigate = useNavigate()
  const currentServerId = useParams().serverId

  const currentServerData = () => {
    if(currentServerId) {
      return servers?.find(server => String(server.id) === currentServerId)
    }
    return {}
  }

  const [formOpen, setFormOpen] = useState(false);
  const handleOpen = () => setFormOpen(true);
  const handleClose = () => setFormOpen(false);

  const servers = useSelector((state) => state.servers.servers);

  const screen = useSelector((state) => state.screen.screen);

  const [urlChangeDisabled, setUrlChangeDisabled] = useState(true)

  const [serverTab, setServerTab] = useState('info')
  const [showSidebar, setShowSidebar] = useState(true)


  const sidebarMainMenu = useRef(null)
  const [openSidebarMainMenu, setOpenSidebarMainMenu] = useState(screen.width > 1000 ? true : false);
  const [desktopOpenMainSidebarMenu, setDesktopOpenMainSidebarMenu] = useState(true)

  const [searchText, setSearchText] = useState('')

  const [filter, setFilter] = useState('all')

  const filters = [
    {
      caption: t('cameras.filter.all'),
      value: 'all',
      onChange: () => {setFilter('all')}
    },
    {
      caption: t('cameras.filter.online'),
      value: 'online',
      onChange: () => {setFilter('online')}
    },
    {
      caption: t('cameras.filter.offline'),
      value: 'offline',
      onChange: () => {setFilter('offline')}
    },
  ]

  const searchFilter = (string, substring) => {
    if (substring !== '') {
      return string.includes(substring)
    }
    return true
  }

  useEffect(() => {
    console.log(servers)
    serverService.getServers()
  }, [])

  return (
    <>
    {formOpen && <AddServerPopup closeServerPopup={handleClose}/>}
    <div ref={sidebarMainMenu}>
      <MainSidebar show={screen.width < 1000 ? openSidebarMainMenu : desktopOpenMainSidebarMenu} active={'/servers'} onClose={() => {setOpenSidebarMainMenu(false)}}/>
    </div>
    <div className={styles['mainWrapper']}>
      {
          showSidebar &&
          (
            <ContentSidebar heading={t('servers.servers')} mobileSidebarOpen={() => {setOpenSidebarMainMenu(true)}} isButtonEnabled={true} onButtonClick={handleOpen} onClose={() => {setShowSidebar(prev => !prev)}}>
              <div className={styles['search-controls-container']}>
                <div className={styles['search-field-container']}>
                  <Search value={searchText} onChange={(e) => {setSearchText(e.target.value)}}/>
                </div>
                <div className={styles['search-filter-container']}>
                  <Filters items={filters} chosen={filter}/>
                </div>
              </div>
              {
                servers?.filter((server, index) => searchFilter(server.name, searchText) || searchFilter(String(server.id), searchText)).map(server => (<ContentItem item={server} index onClick={() => { if (screen.width < 1000) setShowSidebar(false); navigate('/servers/' + String(server.id))}} active={currentServerId === String(server.id)} icon={serverIcon} />))
              }
            </ContentSidebar>
          )
      }
      <div className={styles['rightBlock']}>
        {
            screen.width < 1000 &&
            (
              <MobileHeader heading={servers?.find(server => String(server.id) === currentServerId)?.name} leftIcon={<BackIcon />} leftOnclick={() => {setShowSidebar(prev => !prev)}}/>
            )
        }
        <div className={styles['content-container']}>
          {
            currentServerId && (
              <>
              {
                screen.width > 1000 &&
                (
                  <div className={styles['content-heading']}>{servers?.find(server => String(server.id) === currentServerId)?.name}</div>
                )
              }
              {
                serverTab === 'info' &&
                
                
                <div className={styles['tab-content-container']}>
                  <Typography sx={{fontWeight: 700, fontSize: '16px'}}>
                    {t('servers.networkAddress')}
                  </Typography>
                  {
                    screen.width < 1000 &&
                    <img src={editIcon} alt="error" style={{marginLeft: '10px'}} />
                  }
                  <div className={styles['server-content-info-url-container']}>
                    <div className={'server-content-info-url-container-item'}>
                      <span>{t('servers.address')}</span>
                      <br />
                      <br />
                      <TextField value={'109.0.0.8'} placeholder={t('109.0.0.8')} style={{width: '183px', height: '34px', fontSize: '14px'}} disabled={urlChangeDisabled} />
                    </div>
                    <div className={'server-content-info-url-container-item'}>
                      <span>{t('servers.port')}</span>
                      <br />
                      <br />
                      <TextField value={'8082'} placeholder={t('8082')} style={{width: '90px', height: '34px', fontSize: '14px'}} disabled={urlChangeDisabled} />
                    </div>
                    <div className={'server-content-info-url-container-item'}>
                      <span>{t('servers.sslPort')}</span>
                      <br />
                      <br />
                      <TextField value={'18080'} placeholder={t('18080')} style={{width: '90px', height: '34px', fontSize: '14px'}} disabled={urlChangeDisabled} />
                    </div>
                    <div className={'server-content-info-url-container-item'}>
                      <span></span>
                      <br />
                      <br />
                      {
                      screen.width > 1000 &&
                        (
                            <img src={editIcon} alt="error" style={{marginLeft: '10px'}} />
                        )
                      }
                    </div>
                  </div>
                  <Typography sx={{mt: 2, fontWeight: 700, fontSize: '16px'}}>
                    {t('servers.status')}
                  </Typography>
                  <Typography sx={{mt: 1, mb: 2}}>
                    {servers?.find(server => String(server.id) === currentServerId)?.comment}
                  </Typography>
                  <CustomButton className={styles['server-settings-button']}>
                    {t('servers.settings')}
                  </CustomButton>
                  <br />
                  <br />
                  <span className={styles['server-settings-secondary']}>
                    {t('servers.parameters')}
                  </span>
                  <br />
                  <br />
                  <span className={styles['server-settings-highlighted']}>
                    {t('servers.diagnostics')}
                  </span>
                  <Typography sx={{fontWeight: 700, fontSize: '16px', mt: 2}}>
                    {t('servers.information')}
                  </Typography>
                  <table className={styles['servers-info-table']}>
                    <tr className={styles['servers-info-row']}>
                      <td>{t('servers.currentCondition')}</td>
                      <td>{currentServerData()?.current_status}</td>
                    </tr>
                    <tr className={styles['servers-info-row']}>
                      <td>{t('servers.configurationName')}</td>
                      <td>{currentServerData()?.name_config}</td>
                    </tr>
                    <tr className={styles['servers-info-row']}>
                      <td>{t('servers.configurationApplyTime')}</td>
                      <td>{currentServerData()?.time_config}</td>
                    </tr>
                    <tr className={styles['servers-info-row']}>
                      <td>{t('servers.archiveSize')}</td>
                      <td>{currentServerData()?.size_archive}</td>
                    </tr>
                    <tr className={styles['servers-info-row']}>
                      <td>{t('servers.archiveWriteSpeed')}</td>
                      <td>{currentServerData()?.speed_archive}</td>
                    </tr>
                    <tr className={styles['servers-info-row']}>
                      <td>{t('servers.archiveWriteByDisks')}</td>
                      <td>{''}</td>
                    </tr>
                    <tr className={styles['servers-info-row']}>
                      <td>{t('servers.diagnosticsSMART')}</td>
                      <td>{currentServerData()?.smart}</td>
                    </tr>
                    <tr className={styles['servers-info-row']}>
                      <td>{t('servers.portField')}</td>
                      <td>{currentServerData()?.port}</td>
                    </tr>
                    <tr className={styles['servers-info-row']}>
                      <td>{t('servers.launchTime')}</td>
                      <td>{currentServerData()?.time_start}</td>
                    </tr>
                    <tr className={styles['servers-info-row']}>
                      <td>{t('servers.serverVersion')}</td>
                      <td>{currentServerData()?.version}</td>
                    </tr>
                  </table>
                </div>
              }

              {
                serverTab === 'license' &&
                <div className={styles['tab-content-container']}>
                  2
                </div>
              }
              
              {
                serverTab === 'cameras' &&
                <div className={styles['tab-content-container']}>
                  3
                </div>
              }
              </>
            )
          }
        </div>
      </div>
    </div>
  </>
);
}



export default Servers;
