import React from 'react';
import { Button, Grid, Typography, Box } from '@mui/material';
import addButtonIcon from '../../assets/icons/addButton.svg'
import styles from './content-side-nav.module.css'

const ContentSideNav = ({heading, isButtonEnabled, onButtonClick=() => {}, children}) => {
  return (
    <div className={styles['nav-container']}>
        <Grid container spacing={2}>
            <Grid item xs={8} sx={{ justifyContent: 'center' }}>
            <Typography variant="h4" component="h5">
                {heading}
            </Typography>
            </Grid>
            {
                isButtonEnabled &&
                (
                    <Grid item xs={4}>
                        <button style={{background: 'none', border: 'none'}} onClick={onButtonClick}>
                            <img src={addButtonIcon} alt="error" />
                        </button>
                    </Grid>
                )
            }

        </Grid>
        {
            children &&
            (
                <Grid container xs={10} spacing={2} sx={{ mt: 2 }}>
                    {children}
                </Grid>
            )
        }
    </div>

  )
}

export default ContentSideNav