import { useState, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/button/button";
import {ReactComponent as PictureIcon} from '../../assets/icons/picture.svg'
import { ZoneLayerTable } from "./zone-layers-table";
import background from '../../assets/image/default2.png'
import ruleService from '../../services/rule.service'
import styles from './automation.module.css'

import '../../assets/css/view.css'

export const Vision = ({ruleName, scenarioId, handleClose}) => {
    const {t} = useTranslation('common')
    const [points, setPoints] = useState([]);
    const [polygonsProperties, setPolygonsProperties] = useState([])
    const [polygons, setPolygons] = useState([])
    const [currentProperties, setCurrentProperties] = useState({type: 'inclusive', status: 'Enabled'})
    const [hideCurrent, setHideCurrent] = useState(false)
    
    const addPolygon = () => {
        if(hideCurrent) {
            setPoints([])
            setHideCurrent(false)
        } else {
            setPolygons([...polygons, points])
            setPolygonsProperties([...polygonsProperties, currentProperties])
            setPoints([])
            setCurrentProperties({type: 'inclusive', status: 'Enabled'})
        }
    }

    const addPoint = useCallback(e => {
        if (!hideCurrent) {
            const bounds = e.target.getBoundingClientRect();
            const clickedX = e.clientX - bounds.left;
            const clickedY = e.clientY - bounds.top;
            
            const newPoint = clickedX + "," + clickedY;
            setPoints([...points, newPoint]);
        }
    }, [setPoints, points]);

    const clear = () => {
        setPoints([])
        setPolygonsProperties([])
        setPolygons([])
        setCurrentProperties({type: 'inclusive', status: 'Enabled'})
        setHideCurrent(false)
    }

    const [selectedFile, setSelectedFile] = useState('')
    const [preview, setPreview] = useState()

    const onSubmit = useCallback(
        async (event) => {
          event.preventDefault()
    
          const isFormValid = true
    
          if (isFormValid) {
            const payload = {
                "name": ruleName,
                "status": String(scenarioId),
                "property" : JSON.stringify({
                    type: 'zone',
                    parameter: 'zone',
                    payload: polygonsProperties.map((properties, index) => {return {properties: properties, polygons: polygons[index]}})
                }),
                "is_on" : true
            }
            try {
                const result = await ruleService.createRule(payload);
                console.log("CHECK RESPONSE", result)
                if (result.data) {
                    handleClose()
                }
            } catch (error) {
                console.log(JSON.stringify(error));
            }
          } else {
            
          }
        },
        [handleClose, polygons, polygonsProperties, ruleName, scenarioId]
    )

    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }

        console.log(e.target.files[0])

        setSelectedFile(e.target.files[0])
    }

    const fileRef = useRef()
    
    return (
        <>
            <div>
                <br />
                <ZoneLayerTable data={polygonsProperties} hideCurrent={hideCurrent} onHideCurrent={setHideCurrent} onSave={addPolygon} currentPolygon={currentProperties} onCurrentChange={setCurrentProperties} />
                <div className={styles['table-controls']}>
                    <div>
                        <Button className={styles['add-layer-button']} onClick={addPolygon}>{t("automation.addLayer")}</Button>
                    </div>
                    <div>
                        <Button className={styles['clear-button']} onClick={clear}>{t("automation.clear")}</Button>
                    </div>
                </div>
                <div className="inputWrapper">
                    <label htmlFor="file" className={'popup-input'}>
                        <PictureIcon stroke='var(--color-icon-stroke)'/>
                        {selectedFile?.length > 0 ? selectedFile : 'Upload file'}
                        <input hidden ref={fileRef} id={"file"} className={'popup-input'} type="file" onChange={onSelectFile}/>
                    </label>
                </div>
                <svg className={"canvas"} onClick={addPoint} style={selectedFile ? { backgroundImage: `url(${preview})`, backgroundSize: '100% auto' } : { }}>
                    {polygons.length > 0 ? 
                        polygons.map((polygon, index) => <polygon className={polygonsProperties[index].type === 'exclusive' ? 'exclusive-polygon' : ''} points={polygon.join(" ")} />)
                    : null}
                    {
                        !hideCurrent &&
                        points.length > 0 ? <polygon className={currentProperties.type === 'exclusive' ? 'exclusive-polygon' : ''} points={points.join(" ")} /> : null
                    }

                </svg>
            </div>
            <div className={'createNewCameraFooter'} style={{background: 'none', padding: '0px'}}>
                <div className="selectCameraWrapperFooterRight" onClick={onSubmit} >
                    {t('automation.create')}
                </div>
            </div>
        </>
    );
  };