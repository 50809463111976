// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rules-table_rule-table__\\+AvHF {
    border-collapse: separate;
    border-spacing: 0 10px;
    width: 100%;
}

.rules-table_heading__CRV2L {
    border: none;
}

.rules-table_middle-cell__ub18r {
    border-bottom: 1px solid var(--color-inactive-filter);
    border-top: 1px solid var(--color-inactive-filter);
    border-left: none;
    border-right: none;
    padding-top: 10px;
    padding-bottom: 10px;
}

.rules-table_left-cell__6ejGm {
    border-radius: 5px;
    border-bottom: 1px solid var(--color-inactive-filter);
    border-top: 1px solid var(--color-inactive-filter);
    border-left: 1px solid var(--color-inactive-filter);;
    border-right: none;
    padding-left: 20px;
}

.rules-table_right-cell__i4Eau {
    border-radius: 5px;
    border-bottom: 1px solid var(--color-inactive-filter);
    border-top: 1px solid var(--color-inactive-filter);
    border-right: 1px solid var(--color-inactive-filter);;
    border-left: none;
    padding-right: 20px;
    width: 1%;
    white-space: nowrap;
}

.rules-table_row__WCqDA {
    border: 1px solid var(--color-inactive-filter);
    border-radius: 5px;
}

.rules-table_middle-cell-align__4hZFb {
    text-align: center;
}

.rules-table_options-button__Ot3s\\+ {
    border: none;
    background: none;
    outline: none;
}

.rules-table_options-button__Ot3s\\+:focus {
    outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/rules-table/rules-table.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,qDAAqD;IACrD,kDAAkD;IAClD,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,qDAAqD;IACrD,kDAAkD;IAClD,mDAAmD;IACnD,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,qDAAqD;IACrD,kDAAkD;IAClD,oDAAoD;IACpD,iBAAiB;IACjB,mBAAmB;IACnB,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,8CAA8C;IAC9C,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".rule-table {\n    border-collapse: separate;\n    border-spacing: 0 10px;\n    width: 100%;\n}\n\n.heading {\n    border: none;\n}\n\n.middle-cell {\n    border-bottom: 1px solid var(--color-inactive-filter);\n    border-top: 1px solid var(--color-inactive-filter);\n    border-left: none;\n    border-right: none;\n    padding-top: 10px;\n    padding-bottom: 10px;\n}\n\n.left-cell {\n    border-radius: 5px;\n    border-bottom: 1px solid var(--color-inactive-filter);\n    border-top: 1px solid var(--color-inactive-filter);\n    border-left: 1px solid var(--color-inactive-filter);;\n    border-right: none;\n    padding-left: 20px;\n}\n\n.right-cell {\n    border-radius: 5px;\n    border-bottom: 1px solid var(--color-inactive-filter);\n    border-top: 1px solid var(--color-inactive-filter);\n    border-right: 1px solid var(--color-inactive-filter);;\n    border-left: none;\n    padding-right: 20px;\n    width: 1%;\n    white-space: nowrap;\n}\n\n.row {\n    border: 1px solid var(--color-inactive-filter);\n    border-radius: 5px;\n}\n\n.middle-cell-align {\n    text-align: center;\n}\n\n.options-button {\n    border: none;\n    background: none;\n    outline: none;\n}\n\n.options-button:focus {\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rule-table": `rules-table_rule-table__+AvHF`,
	"heading": `rules-table_heading__CRV2L`,
	"middle-cell": `rules-table_middle-cell__ub18r`,
	"left-cell": `rules-table_left-cell__6ejGm`,
	"right-cell": `rules-table_right-cell__i4Eau`,
	"row": `rules-table_row__WCqDA`,
	"middle-cell-align": `rules-table_middle-cell-align__4hZFb`,
	"options-button": `rules-table_options-button__Ot3s+`
};
export default ___CSS_LOADER_EXPORT___;
