// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textfield_textfield__vLsW1 {
    height: 38px;
    border: 1px solid #E6E7EB;
    border-radius: 5px;
    outline: none;
    padding: 8px 15px 8px 15px;
    width: 100%
}

.textfield_textfield__vLsW1:focus {
    outline: none
}

.textfield_textfield__vLsW1::placeholder {
    color: #8E99A0
}

.textfield_textfield__vLsW1::-ms-input-placeholder {
    color: #8E99A0;
}


.textfield_error-input__3J6A4 {
    border: 1px solid var(--color-error-input-border);
    background-color: var(--color-error-input-background);
}

.textfield_field-icon__cn5n8 {
    float: right;
    margin-left: -25px;
    margin-top: -25px;
    position: relative;
    z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/components/textfield/textfield.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,0BAA0B;IAC1B;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI,cAAc;AAClB;;;AAGA;IACI,iDAAiD;IACjD,qDAAqD;AACzD;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;AACf","sourcesContent":[".textfield {\n    height: 38px;\n    border: 1px solid #E6E7EB;\n    border-radius: 5px;\n    outline: none;\n    padding: 8px 15px 8px 15px;\n    width: 100%\n}\n\n.textfield:focus {\n    outline: none\n}\n\n.textfield::placeholder {\n    color: #8E99A0\n}\n\n.textfield::-ms-input-placeholder {\n    color: #8E99A0;\n}\n\n\n.error-input {\n    border: 1px solid var(--color-error-input-border);\n    background-color: var(--color-error-input-background);\n}\n\n.field-icon {\n    float: right;\n    margin-left: -25px;\n    margin-top: -25px;\n    position: relative;\n    z-index: 10;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textfield": `textfield_textfield__vLsW1`,
	"error-input": `textfield_error-input__3J6A4`,
	"field-icon": `textfield_field-icon__cn5n8`
};
export default ___CSS_LOADER_EXPORT___;
