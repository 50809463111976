import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";
import classNames from "classnames";
import { store } from "../../store";
import { setDarkTheme, setLightTheme } from "../../features/theme/themeSlice";
import styles from './dark-mode-toggle.module.css'

import { ReactComponent as SunIcon }  from '../../assets/icons/sun.svg'
import { ReactComponent as MoonIcon } from '../../assets/icons/moon.svg'

export const DarkModeToggle = () => {

  const isDarkTheme = useSelector((state) => state.theme.dark);

  const systemPrefersDark = useMediaQuery(
    {
      query: "(prefers-color-scheme: dark)",
    },
    undefined,
    (isSystemDark) => {setIsDark(isSystemDark)}
  );

  const [isDark, setIsDark] = useState(isDarkTheme);

  useEffect(() => {
    if (isDark) {
      store.dispatch(setDarkTheme())
      document.body.classList.add('dark');
    } else {
      store.dispatch(setLightTheme())
      document.body.classList.remove('dark');
    }
  }, [isDark]);

  return (
    <>
    <div className={styles['modes-container']}>
      <button className={classNames(styles['mode-block'], !isDark ? styles['mode-block-active']: '')} onClick={() => {setIsDark(false)}}>
        <SunIcon stroke={isDark ? "var(--color-icon-stroke)" : "var(--color-content-highlight)" }/>
      </button>
      <button className={classNames(styles['mode-block'], isDark ? styles['mode-block-active']: '')} onClick={() => {setIsDark(true)}}>
        <MoonIcon stroke={isDark? "var(--color-content-highlight)" :"var(--color-icon-stroke)"}/>
      </button>
    </div>
    </>
  );
};