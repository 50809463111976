import styles from './toggle.module.css'
import "react-toggle/style.css"
import Toggle from 'react-toggle'
import '../../assets/css/view.css'

export const ToggleSwitch = ({checked, defaultChecked, onChange, label, children, ...props}) => {
  return (
    <div className={styles['toggle-container']}>
        {children}
        <Toggle
        id='status'
        className={'toggle'}
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={onChange}
        {...props}
        />
    </div>
  );
}
