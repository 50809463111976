// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-stream_error-container__tJeZJ {
    width: 100%;
    height: 100%;
    background-color: var(--color-camera-popup);
    color: var(--color-status-red);
    font-size: 14px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px
}`, "",{"version":3,"sources":["webpack://./src/components/video-stream/video-stream.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,2CAA2C;IAC3C,8BAA8B;IAC9B,eAAe;IACf,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;IACvB;AACJ","sourcesContent":[".error-container {\n    width: 100%;\n    height: 100%;\n    background-color: var(--color-camera-popup);\n    color: var(--color-status-red);\n    font-size: 14px;\n    border-radius: 5px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n    padding: 10px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error-container": `video-stream_error-container__tJeZJ`
};
export default ___CSS_LOADER_EXPORT___;
