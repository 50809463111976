// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu_menu-container__JkHAd {
    position: relative;
}

.menu_menu__uksdi {
    position: absolute;
    top: 110%;
    width: 113px;
    z-index: 10;
    background-color: var(--color-content);
    border-radius: 5px;
    border: 1px solid var(--color-inactive-filter);
    overflow: hidden;
    right: 0
}

.menu_menu-item__bs67w {
    height: 48px;
    width: 100%;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-size: 14px;
    font-weight: 450;
    padding-left: 15px;
    border-top: 1px solid var(--color-inactive-filter);
    color: var(--color-main-typography)
}

.menu_menu-item-first__G4IyR {
    border-top: none
}`, "",{"version":3,"sources":["webpack://./src/components/menu/menu.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,YAAY;IACZ,WAAW;IACX,sCAAsC;IACtC,kBAAkB;IAClB,8CAA8C;IAC9C,gBAAgB;IAChB;AACJ;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,SAAS;IACT,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,kDAAkD;IAClD;AACJ;;AAEA;IACI;AACJ","sourcesContent":[".menu-container {\n    position: relative;\n}\n\n.menu {\n    position: absolute;\n    top: 110%;\n    width: 113px;\n    z-index: 10;\n    background-color: var(--color-content);\n    border-radius: 5px;\n    border: 1px solid var(--color-inactive-filter);\n    overflow: hidden;\n    right: 0\n}\n\n.menu-item {\n    height: 48px;\n    width: 100%;\n    border: none;\n    background: none;\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    gap: 10px;\n    font-size: 14px;\n    font-weight: 450;\n    padding-left: 15px;\n    border-top: 1px solid var(--color-inactive-filter);\n    color: var(--color-main-typography)\n}\n\n.menu-item-first {\n    border-top: none\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu-container": `menu_menu-container__JkHAd`,
	"menu": `menu_menu__uksdi`,
	"menu-item": `menu_menu-item__bs67w`,
	"menu-item-first": `menu_menu-item-first__G4IyR`
};
export default ___CSS_LOADER_EXPORT___;
