import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './event-card.module.css'
import starIcon from '../../assets/icons/star.svg'
import starHighlightedIcon from '../../assets/icons/starEvent.svg'
import defaultImage from '../../assets/image/default3.png'

export const EventCard = ({text, info_subject, src, create_at, chosen, onStarClick, ...props}) => {

    const {t} = useTranslation("common")

  return (
    <div className={styles['card-container']} {...props}>
        {
            src &&
            <div className={styles['card-image-container']}>
                <img className={styles['card-image']} src={src} alt="" />
            </div>
        }
        <div className={styles['card-description']}>
            <div className={styles['card-description-info']}>
                <div className={styles['card-description-main-text']}>
                    {text}
                </div>
                <div className={styles['card-description-secondary-text']}>
                    {info_subject + ' ' + t('events.at')+ ' ' + create_at}
                </div>
            </div>
            <button className={styles['start-button']} onClick={onStarClick}>
                <img src={chosen ? starHighlightedIcon: starIcon} alt="" />
            </button>
        </div>
    </div>
  );
}
