import Head from 'next/head';
import { useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button as CustomButton } from '../../components/button/button';
import { TextField } from '../../components/textfield/textfield';
import { MainSidebar } from '../../components/main-sidebar/main-sidebar';
import { ContentItem } from '../../components/content-item/content-item';
import { ContentSidebar } from '../../components/content-sidebar/content-sidebar';
import {useRef} from "react"

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import ContentSideNav from '../../components/content-side-nav/content-side-nav';
import ContentListItem from '../../components/content-list-item/content-list-item';
import cameraService from '../../services/cameras.service'
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {useTranslation} from "react-i18next";
import cameraIcon from '../../assets/icons/camerasItemIcon.svg'
import { Search } from '../../components/search/search';
import { MenuTabs } from '../../components/menu-tabs/menu-tabs';
import { Filters } from '../../components/filters/filters';
import AddCameraPopup from './AddCameraPopup';
import { MobileHeader } from '../../components/mobile-header/mobile-header';
import { VideoStream } from '../../components/video-stream/video-stream';
import AddRulePopup from './add-rule-popup';
import { RulesTable } from '../../components/rules-table/rules-table';
import ruleService from '../../services/rule.service'
import { ToggleSwitch } from '../../components/toggle/toggle';
import notificationService from '../../services/notification.service'
import { EventCard } from '../../components/event-card/event-card';
import { HLSVideoStream } from '../../components/video-stream-hls/video-stream-hls';

import { ReactComponent as VideoIcon } from '../../assets/icons/videoIcon.svg'
import { ReactComponent as BarChartIcon } from '../../assets/icons/barChart.svg'
import { ReactComponent as GearIcon } from '../../assets/icons/gear.svg'
import { ReactComponent as BackIcon } from '../../assets/icons/left.svg'
import { ReactComponent as AutomationIcon } from '../../assets/icons/automationIcon.svg'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'


import styles from './cameras.module.css'
import automationStyles from './automation.module.css'
import { Spinner } from '../../components/spinner/spinner';


const Cameras = () => {
  const {t} = useTranslation("common")
  const navigate = useNavigate()
  const currentCameraId = useParams().cameraId

  const [formOpen, setFormOpen] = useState(false);
  const handleOpen = () => setFormOpen(true);
  const handleClose = () => setFormOpen(false);
  const [formRuleOpen, setFormRuleOpen] = useState(false);

  const rules = useSelector((state) => state.rules.rules)
  const ruleDelete = (id) => {
    console.log("CHECK RULE ID", id)
    ruleService.deleteRule({id: id, "status" : String(currentCameraId)})
  }

  const cameras = useSelector((state) => state.cameras.cameras);
  const screen = useSelector((state) => state.screen.screen);
  const notifications = useSelector((state) => state.notifications.notifications);

  const currentCameraData = useMemo(() => {
    if(currentCameraId) {
      return cameras.find(camera => String(camera.id) === currentCameraId)
    }
    return {}
  }, [cameras, currentCameraId])

  const [value, setValue] = useState(0);

  const [showSidebar, setShowSidebar] = useState(true)

  const [cameraTab, setCameraTab] = useState('video')
  const [searchRuleText, setSearchRuleText] = useState('')


  const [formFields, setFormFields] = useState(false)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [searchText, setSearchText] = useState('')

  const tabs = [
    {
      caption: t('cameras.videoStream'),
      value: 'video',
      icon: <VideoIcon stroke={cameraTab === 'video' ? "var(--color-content-highlight)" : "var(--color-icon-stroke)" }/>,
      onClick: () => {console.log('set video'); console.log(cameraTab); setCameraTab('video')}
    },
    {
      caption: t('cameras.analytics'),
      value: 'analytics',
      icon: <BarChartIcon stroke={cameraTab === 'analytics' ? "var(--color-content-highlight)" : "var(--color-icon-stroke)" }/>,
      onClick: () => {setCameraTab('analytics')}
    },
    {
      caption: t('cameras.settings'),
      value: 'settings',
      icon: <GearIcon stroke={cameraTab === 'settings' ? "var(--color-content-highlight)" : "var(--color-icon-stroke)" }/>,
      onClick: () => {setCameraTab('settings')}
    },
    {
      caption: t('automation.automation'),
      value: 'automation',
      icon: <AutomationIcon stroke={cameraTab === 'automation' ? "var(--color-content-highlight)" : "var(--color-icon-stroke)" }/>,
      onClick: () => {setCameraTab('automation')}
    },
    {
      caption: t('events.events'),
      value: 'notifications',
      icon: <CalendarIcon stroke={cameraTab === 'notifications' ? "var(--color-content-highlight)" : "var(--color-icon-stroke)" }/>,
      onClick: () => {setCameraTab('notifications')}
    },
  ]

  const [filter, setFilter] = useState('')

  const [status, setStatus] = useState('Disabled')

  const filters = [
    {
      caption: t('cameras.filter.all'),
      value: '',
      onChange: () => {setFilter('')}
    },
    {
      caption: t('cameras.filter.online'),
      value: 'online',
      onChange: () => {setFilter('online')}
    },
    {
      caption: t('cameras.filter.offline'),
      value: 'offline',
      onChange: () => {setFilter('offline')}
    },
  ]

  const searchFilter = (string, substring) => {
    if (substring !== '') {
      return string.includes(substring)
    }
    return true
  }

  const dropDownItems = [
    {
      caption: t('cameras.dropdown.addCamera'),
      value: 'add_camera',
      onClick: handleOpen
    },
    {
      caption: t('cameras.dropdown.settings'),
      value: 'settings',
    },
  ]

  const [loading, setLoading] = useState(false)

  const [toggleLoading, setToggleLoading] = useState(false)
  
  const sidebarMainMenu = useRef(null)
  const [openSidebarMainMenu, setOpenSidebarMainMenu] = useState(screen.width > 1000 ? true : false);
  const [desktopOpenMainSidebarMenu, setDesktopOpenMainSidebarMenu] = useState(true)

  const onCameraStatusChange = useCallback(
    async (id) => {
      const cameraToUpdate = cameras.find(camera => camera.id === id)
      const newStatus = cameraToUpdate.status === "Enabled" ? "Disabled" : "Enabled"
      console.log(newStatus, "CHECK STATUS")
      const payload = {
        ...cameraToUpdate,
        status: newStatus
      }
      try {
          setToggleLoading(true)
          const result = await cameraService.updateCamera(payload);
          console.log("CHECK STATUS", newStatus)
          if (result.data) {
            const timestamp = new Date().toUTCString();
            const turnOnString = newStatus === "Enabled" ? "включена" : "выключена"
            console.log("CHECK TURN ON", turnOnString)
            const res = await notificationService.createNotification({
              "text": currentCameraData.name + ' | ID:' + currentCameraData.id,
              "create_at": timestamp,
              "info_subject":"Камера " + currentCameraData.id + " была " + turnOnString, 
            })
            if (res.data) {
              console.log("CHECK CAMERA NOTIFICATION", res)
              setToggleLoading(false)
            }
          }
      } catch (error) {
          console.log(JSON.stringify(error));
      }
    }, [cameras, currentCameraData])

  const deleteCamera = useCallback(
    async (id) => {
        const payload = {
            "id": id,
        }
        const timestamp = new Date().toUTCString();
        try {
            setLoading(true)
            const result = await cameraService.deleteCamera(payload);
            console.log("CHECK DATA", result)
            if (result.data) {
              const res = await notificationService.createNotification({
                "text": currentCameraData.name + ' | ID:' + currentCameraData.id,
                "create_at": timestamp,
                "info_subject":"Камера " + currentCameraData.id + " была удалена", 
              })
              navigate('/cameras')
            }
        } catch (error) {
            console.log(JSON.stringify(error));
        }
  }, [currentCameraData, navigate])

  const makeFavorite = (notification) => {
    notificationService.updateNotification({...notification, is_favourites: !notification?.is_favourites})
  }

  const filteredCameras = useMemo(
    () => {
      return cameras?.filter((camera, index) => searchFilter(camera?.status, filter))
      .filter((camera, index) => searchFilter(camera?.name, searchText) || searchFilter(String(camera.id), searchText)) 
    }, [cameras, filter, searchText])
  
  const filteredNotifications = useMemo(
    () => {
      return notifications?.filter(notification => notification.text.split(':')[1] === currentCameraId)
    }, [notifications, currentCameraId])
  
  const filteredRules = useMemo(
    () => {
      return rules[currentCameraId]?.filter((rule, index) => searchFilter(rule.name, searchRuleText)).map((rule) => { return {...rule, onDelete: () => {ruleDelete(rule.id)}}})
    }, [rules, currentCameraId]
  )

  const sidebarHeading = useMemo(
    () => {
      return cameras.find(camera => String(camera.id) === currentCameraId)?.name
    }, [cameras, currentCameraId]
  )

  useEffect(() => {
    cameraService.getCameras()
    notificationService.getNotifications()
  }, [])

  useEffect(() => {
    console.log("CHECK RULES", rules)
    console.log(currentCameraId)
    ruleService.getRules(currentCameraId)
    setStatus(currentCameraData.status)
  }, [currentCameraId])

  return (
    <>
    {formOpen && <AddCameraPopup closeCameraPopup={handleClose} handleClose={() => {setFormOpen(false)}}/>}
    {formRuleOpen && <AddRulePopup handleClose={() => setFormRuleOpen(false)} scenarioId={Number(currentCameraId)} />}
    <div ref={sidebarMainMenu}>
      <MainSidebar show={screen.width < 1000 ? openSidebarMainMenu : desktopOpenMainSidebarMenu} active={'/cameras'} onClose={() => {setOpenSidebarMainMenu(false)}}/>
    </div>
    <div className={styles['mainWrapper']}>
      {
        showSidebar &&
        (
          <ContentSidebar mobileDropDownItems={dropDownItems} mobileSidebarOpen={() => {setOpenSidebarMainMenu(true)}} heading={t('cameras.cameras')} isButtonEnabled={true} onButtonClick={handleOpen} onClose={() => {setShowSidebar(prev => !prev)}}>
            <div className={styles['search-controls-container']}>
              <div className={styles['search-field-container']}>
                <Search value={searchText} onChange={(e) => {setSearchText(e.target.value)}}/>
              </div>
              <div className={styles['search-filter-container']}>
                <Filters items={filters} chosen={filter}/>
              </div>
            </div>
            {
              filteredCameras.map(camera => (<ContentItem item={camera} index onClick={() => { if (screen.width < 1000) setShowSidebar(false); navigate('/cameras/' + String(camera.id))}} active={currentCameraId === String(camera.id)} icon={cameraIcon} />))
            }
          </ContentSidebar>
        )
      }
      <div className={styles['rightBlock']}>
        {
            screen.width < 1000 &&
            (
              <MobileHeader heading={sidebarHeading} leftIcon={<BackIcon />} leftOnclick={() => {setShowSidebar(prev => !prev)}}/>
            )
        }
        <div className={styles['content-container']}>
          {
            currentCameraId && (
              <>
              {
                screen.width > 1000 &&
                (
                  <>
                    <div className={styles['content-heading-container']}>
                      <div className={styles['content-heading']}><span>{cameras.find(camera => String(camera.id) === currentCameraId)?.name}</span></div>
                      <ToggleSwitch defaultChecked={currentCameraData?.status === 'Enabled'} checked={currentCameraData?.status === 'Enabled' ? true : false} onChange={() => {onCameraStatusChange(Number(currentCameraId))}}>
                        {
                          toggleLoading ?
                          <Spinner dark={true}/> :
                          <label htmlFor='status'>{currentCameraData?.status === 'Enabled' ? 'on' : 'off'}</label>
                        }
                      </ToggleSwitch>
                    </div>
                  </>
                )
              }
              <MenuTabs tabs={tabs} active={cameraTab} />
              {
                cameraTab === 'video' &&
                <div className={styles['tab-content-container']}>
                  {
                    currentCameraId && status === "Enabled" &&
                    (
                    <>
                      <VideoStream key={currentCameraId} webSocketId={String(currentCameraId)}/>
                    </>
                    )
                  }
                </div>
              }

              {
                cameraTab === 'analytics' &&
                <div className={styles['tab-content-container']}>
                  2
                </div>
              }
              
              {
                cameraTab === 'settings' &&
                <div className={styles['tab-content-container']}>
                  <button className="deleteButton" onClick={()=>{deleteCamera(Number(currentCameraId))}}>
                    {t('cameras.deleteCamera')}
                  </button>

                </div>
              }

              {
                cameraTab === 'automation' &&
                  <div className={automationStyles['tab-content-container']}>
                    <div className={automationStyles['search-controls']}>
                      <div className={automationStyles['search-container']}>
                       {
                          rules[currentCameraId]?.length > 0 &&
                          <Search value={searchRuleText} onChange={(e) => {setSearchRuleText(e.target.value)}} clearIcon={true} bordered={true}/>

                        }
                      </div>
                      <button className="rightBlockAddCameraBtn" onClick={()=>{setFormRuleOpen(true)}}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 3.75V14.25M3.75 9H14.25" stroke="#5E5873" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        {t('automation.newRule')}
                      </button>
                    </div>
                    {
                      rules[currentCameraId]?.length > 0 &&
                      <RulesTable data={filteredRules}/>
                    }
                  </div>
              }
              {
                cameraTab === 'notifications' &&
                  <div className={automationStyles['tab-content-container']}>
                    <div className={automationStyles['notification-container']}>
                    {
                      filteredNotifications.map(notification => <EventCard {...notification} chosen={notification?.is_favourites} onStarClick={() => {makeFavorite(notification)}}/>)
                    }
                    </div>
                  </div>
              }
              </>
            )
          }
        </div>
      </div>
    </div>
  </>
);
}



export default Cameras;
