import React from 'react';
import { useState } from 'react';
import { Paper, Typography, Grid, Box, Card } from '@mui/material';

import styles from './content-list-item.module.css'

const ContentListItem = ({name, id, isOn, selected, onClick, icon}) => {
    const [elevation, setElevation] = useState(1)
    return (
        <>
            <Paper elevation={elevation} sx={{py: 0, px:0, position: 'relative', height: '90px'}} onMouseEnter={() => setElevation(5)} onMouseLeave={() => setElevation(1)} onClick={onClick}>
                <div className={styles['camera-item']}>
                    {
                        selected &&
                        (
                        <Box
                            sx={{
                                width: '5px',
                                height: '100%',
                                bgcolor: '#0095FF',
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                borderRadius: '4px 0 0 4px'
                            }}
                        >  
                        </Box>
                        )
                    }
                    <Grid container sx={{mx: 2}}>
                        <Grid item xs={12} sx={{mt: 2}}>
                            <span className={styles['camera-name']}>
                                {name}
                            </span>
                        </Grid>
                        <Grid item xs={12} sx={{mt: 2}}>
                            <div className={styles['camera-id-row']}>
                                <img src={icon} alt="" />
                                {
                                    id && 
                                    (
                                        <span className={styles['camera-id']}>
                                            {id}
                                        </span>
                                    )
                                }
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        </>

    )
}

export default ContentListItem