import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from './video-stream.module.css'

export const VideoStream = ({webSocketId, ...props}) => {
    const [streamError, setStreamError] = useState(null)
    const {t} = useTranslation('common')

    let ws;

    useEffect(() => {

        console.log(webSocketId)
        
    
        let verbose = true;
        
        let buffering_sec = "0.8"; 

        let buffering_sec_seek = buffering_sec * 0.9;
        
        
        let buffering_sec_seek_distance = buffering_sec * 0.5;
        

        let lastSeekTime = undefined

        
        
        var mimeType = 'video/mp4; codecs="avc1.42001e, mp4a.40.5"';

        let stream_started = false; 

        
        let ms = new MediaSource();

        
        let queue = [];

        let stream_live; 
        let seeked = false; 
        let cc = 0;

        let source_buffer;
        var decoded_arr;
        var df;
        var seek_to;
        var memview;
        var inp;
        
        

        function toInt(arr, index) { 
            let dv = new DataView(arr.buffer, 0);
            return dv.getInt32(index, false); 
        }

        function toString(arr, fr, to) { 
            
            return String.fromCharCode.apply(null, arr.slice(fr, to));
        }
        function setlatencyLim(value) {
            buffering_sec= Number(value)
            
            buffering_sec_seek = buffering_sec * 0.9;
            buffering_sec_seek_distance = buffering_sec * 0.5;
        }

        function Utf8ArrayToStr(array) {
            let out, i, len, c;
            let char2, char3;
            out = "";
            len = array.length;
            i = 0;
            while (i < len) {
                c = array[i++];
                switch (c >> 4) {
                    case 7:
                        out += String.fromCharCode(c);
                        break;
                    case 13:
                        char2 = array[i++];
                        out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
                        break;
                    case 14:
                        char2 = array[i++];
                        char3 = array[i++];
                        out += String.fromCharCode(((c & 0x0F) << 12) |
                            ((char2 & 0x3F) << 6) |
                            ((char3 & 0x3F) << 0));
                        break;
                }
            }
            return out;
        }

        let started = false;
        
        
        
        
        function putPacket(arr) {
            let data = new Uint8Array(arr);
            if (data[0] === 9 && !started) {
                started = true;
                let codecs  
                decoded_arr = data.slice(1);
                if (window.TextDecoder) {
                    codecs = new TextDecoder("utf-8").decode(decoded_arr);
                } else {
                    codecs = Utf8ArrayToStr(decoded_arr);
                }
                if (verbose) {
                    console.log('first packet with codec data: ' + codecs);
                }

                
                let codecPars = mimeType + ';codecs="' + codecs + '"';
                if (!(window.MediaSource && window.MediaSource.isTypeSupported(codecPars))) {
                    console.log(codecPars + "Not supported");
                }
                ms.duration = buffering_sec;
                try{
                    source_buffer = ms.addSourceBuffer(codecPars);
                    source_buffer.mode = 'sequence';
                    source_buffer.addEventListener("updateend", loadPacket);
                } catch {
                    ws.close()
                    setStreamError(t('videoStream.compatibilityError'))
                }
            } else {
                
                let latest = stream_live.duration;
                if ((stream_live.duration >= buffering_sec) &&
                    ((latest - stream_live.currentTime) > buffering_sec_seek)) {
                    console.log("seek from ", stream_live.currentTime, " to ", latest);
                    df = (stream_live.duration - stream_live.currentTime); 
                    if ((df > buffering_sec_seek)) {
                        seek_to = stream_live.duration - buffering_sec_seek_distance;
                        stream_live.currentTime = seek_to;
                    }
                }

                data = arr;
                if (!stream_started) {
                    if (verbose) {
                        console.log("Streaming started: ", memview[0], memview[1], memview[2], memview[3], memview[4]);
                    }
                    source_buffer.appendBuffer(data);
                    stream_started = true;
                    cc = cc + 1;
                    return;
                }

                queue.push(data); 
                if (verbose) {
                    console.log("queue push:", queue.length);
                }
            }
        }


        function loadPacket() { 
            if (!source_buffer.updating) { 
                if (queue.length > 0) {

                    inp = queue.shift(); 
                    if (verbose) {
                        console.log("queue pop:", queue.length);
                    }

                    let memview = new Uint8Array(inp);

                    if (verbose) {
                        console.log(" ==> writing buffer with", memview[0], memview[1], memview[2], memview[3]);
                    }

                    source_buffer.appendBuffer(inp);
                    cc = cc + 1;
                } else { 
                    stream_started = false;
                }
            } else { 
            }
        }


        function opened() {
            let urlStr = window.location.href
            let url = new URL(urlStr);
            let suuid = "id_12"
            if (suuid === undefined)
                suuid = "stream1";
            let host = window.location.host
            ws = new WebSocket('wss://socket.teo-bot.online/ws/stream?suuid=id_' + webSocketId);
            ws.binaryType = "arraybuffer";
            ws.onmessage = function (event) {
                try {
                    putPacket(event.data);
                } catch {
                    ws.close()
                    setStreamError(t('videoStream.compatibilityError'))
                }
            };
        }

        function startup() {
            ms.addEventListener('sourceopen', opened, false);

            
            stream_live = document.getElementById("stream_live" + String(webSocketId));
            
            
            stream_live.src = window.URL.createObjectURL(ms);

        }


        startup()


    }, [webSocketId])

    useEffect(() => {
        return () => {
            if(ws) {
                ws.close()
            }
        }
    }, [])

    return (
        <>
        {
            !streamError ?
            <video id={"stream_live" + String(webSocketId)} style={{"width" : "800px"}} muted="" controls="" autoPlay="" preload="auto" {...props}>
                Your browser does not support the video tag
            </video> : 
            <div className={styles['error-container']} ><div>{streamError}</div></div>
        }
        </>
    );
}
