import classNames from 'classnames';
import { useState } from 'react';

import styles from './dropdown-list.module.css'

export const DropdownList = ({items, ...props}) => {

    const [open, setOpen] = useState(false)
    console.log(items)
    
    return (
            <div className={styles['dropdown']}>
                {
                    items?.map((item, index) => (
                        <div className={classNames(styles['dropdown-item'], index === 0 ? styles['dropdown-item-first'] : '')} onClick={item.onClick}>
                            {item.caption}
                        </div>
                    ))
                }
            </div>
    );
}
