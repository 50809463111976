import { useRef } from 'react';
import { useSelector } from 'react-redux';
import styles from './main-sidebar.module.css'
import { MainSidebarItem } from '../main-sidebar-item/main-sidebar-item';
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { DarkModeToggle } from '../dark-mode-toggle/dark-mode-toggle';

import {ReactComponent as BellIcon} from '../../assets/icons/bellIcon.svg'
import {ReactComponent as BellIconActive} from '../../assets/icons/bellIconActive.svg'
import {ReactComponent as ViewIcon} from '../../assets/icons/views.svg'
import {ReactComponent as ViewIconActive} from '../../assets/icons/viewsActive.svg'
import {ReactComponent as CamerasIconActive} from '../../assets/icons/camerasActive.svg'
import {ReactComponent as CamerasIcon} from '../../assets/icons/cameras.svg'
import {ReactComponent as ServersIconActive} from '../../assets/icons/serversActive.svg'
import {ReactComponent as ServersIcon} from '../../assets/icons/servers.svg'
import {ReactComponent as AutomationIcon} from '../../assets/icons/automationIcon.svg'
import {ReactComponent as AutomationIconActive} from '../../assets/icons/automationIconActive.svg'
import {ReactComponent as ArchiveIcon} from '../../assets/icons/archive.svg'
import {ReactComponent as ArchiveIconActive} from '../../assets/icons/archiveActive.svg'
import {ReactComponent as SettingsIcon} from '../../assets/icons/settings.svg'
import {ReactComponent as SettingsIconActive} from '../../assets/icons/settingsActive.svg'
import {ReactComponent as ProIcon} from '../../assets/icons/proIcon.svg'

import {ReactComponent as CloseIcon} from '../../assets/icons/x-close.svg'
import {ReactComponent as LogoWithText} from '../../assets/icons/logoWithText.svg'

import closeIcon from '../../assets/icons/chevron-right.svg'
import logoIcon from '../../assets/icons/logo.svg'
import userTestIcon from '../../assets/icons/testUser.png'

export const MainSidebar = ({active, show, onClose, onOpen, ...props}) => {
  const [t, i18n] = useTranslation('common')
  const location = useLocation();
  console.log(props)

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  const screen = useSelector((state) => state.screen.screen);

  const proMenuItems = [
    {
      label: t("sidebar.events"),
      url: '/events',
      activeIconSrc: <BellIconActive />,
      disabledIconSrc: <BellIcon stroke='var(--color-sidebar-typography-disabled)' />,
    },
    {
      label:  t("sidebar.views"),
      url: '/views',
      activeIconSrc: <ViewIconActive />,
      disabledIconSrc: <ViewIcon stroke='var(--color-sidebar-typography-disabled)' />,
    },
  ]

  const menuItems = [
    {
      label: t("sidebar.cameras"),
      url: '/cameras',
      activeIconSrc: <CamerasIconActive />,
      disabledIconSrc: <CamerasIcon stroke='var(--color-sidebar-typography-disabled)' />,
    },
    {
      label: t("sidebar.archive"),
      url: '/archive',
      activeIconSrc: <ArchiveIconActive />,
      disabledIconSrc: <ArchiveIcon stroke='var(--color-sidebar-typography-disabled)' />,
    },
    {
      label: t("sidebar.settings"),
      url: '/settings',
      activeIconSrc: <SettingsIconActive />,
      disabledIconSrc: <SettingsIcon stroke='var(--color-sidebar-typography-disabled)' />,
    },
  ]

  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  
  if (!show) {
    return null
  }

  return (
    <div className={classNames(styles.sidebar, 'dark')} {...props}>
      <div className={classNames(styles['sidebar-pro'], 'dark')}>
        {
          screen.width < 1000 &&
          (
            <div className={styles['mobile-header']}>
              <div> <LogoWithText /></div>
              <div onClick={onClose}><CloseIcon /></div>
            </div>
          )
        }
        {
          screen.width > 1000 &&
          (
            <>
              <img src={logoIcon} alt="error" />
              <ProIcon />
            </>
          )
        }
        {
          proMenuItems.map(item => <MainSidebarItem key={item.url} {...item} active={active === item.url} />)
        }
      </div>
      <div className={styles['sidebar-regular']}>
        {
          menuItems.map(item => {console.log(location.pathname === item.url); return <MainSidebarItem key={item.url} {...item} active={active === item.url} />})
        }
        <div className={styles['sidebar-user-container']}>
          <img src={userTestIcon} alt={'error'} onClick={onClose} />
          {screen.width < 1000 &&
          <span>email@email.com</span>
          }
        </div>
      </div>
      {
          screen.width > 1000 &&
          (
            <div className={styles['sidebar-close']}>
              <button className={styles['close-button']}>
                <img src={closeIcon} alt={'error'} onClick={onClose} />
              </button>
            </div>
          )
      }
    </div>
  );
}