import classNames from 'classnames';
import { useState } from 'react';

import styles from './menu.module.css'

export const Menu = ({items, ...props}) => {
    
    return (
        <div className={styles['menu-container']}>
            <div className={styles['menu']}>
                {
                    items.map((item, index) => (
                        <button className={classNames(styles['menu-item'], index === 0 ? styles['menu-item-first'] : '')} onClick={item.onClick}>
                            {item.caption}                    
                        </button>
                    ))
                }
            </div>
        </div>
    );
}
