import authService from './auth.service.js'
import { setViews, updateSingleView } from "../features/views/viewsSlice";
import { store } from "../store";

import http from "../utils/http-client";

const getAuthUser = authService.getAuthUser

const getViews = () => {
    const data = {
        "limit":1000,
        "offset":0
    }
    return http.post('/group/get-list', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result).data;
            console.log(parsed, 'getViews')

            if(parsed?.result === false) {
                store.dispatch(setViews([]))
                return []
            }
            store.dispatch(setViews(parsed.list))
            return parsed.list;
        }]
    }).catch((err) => {});
}

const createView = (data) => {
    const user = getAuthUser()
    return http.post('/group/create', {...data, company_id: user.company_id} , {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            console.log(parsed, 'createView')
            // store.dispatch(addCamera(parsed.data))
            return parsed;
        }]
    });
}

const linkCamerasToView = (data) => {
    const user = getAuthUser()
    console.log(data)
    return http.post('/setting-group/create', data , {
        transformResponse: [(result) => {
            // const parsed = JSON.parse(result);
            console.log(result, 'linkCamerasToView REQUEST')
            // store.dispatch(addCamera(parsed.data))
            // return parsed;
        }]
    });
}

const updateView = (data) => {
    console.log("CHECK PAYLOAD", data)
    return http.post('/group/update', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            store.dispatch(updateSingleView(parsed.data))
            return parsed;
        }]
    });
}


// const getCamera = (data) => {
//     return http.post('/camera/get', data, {
//         transformResponse: [(result) => {
//             const parsed = JSON.parse(result).data;
//             return parsed;
//         }]
//     }).catch((err) => {});
// }
//
// const createCamera = (data) => {
//     const user = getAuthUser()
//     return http.post('/camera/create', {...data, 'company_id': user['company_id']}, {
//         transformResponse: [(result) => {
//             const parsed = JSON.parse(result);
//             console.log(parsed)
//             store.dispatch(addCamera(parsed.data))
//             return parsed;
//         }]
//     });
// }

// const updateCamera = (data) => {
//     return http.post('/camera/update', data, {
//         transformResponse: [(result) => {
//             const parsed = JSON.parse(result);
//             store.dispatch(updateSingleCamera(parsed.data))
//             return parsed;
//         }]
//     });
// }
//
// const deleteCamera = (data) => {
//     return http.post('/camera/delete', data, {
//         transformResponse: [(result) => {
//             const parsed = JSON.parse(result);
//             store.dispatch(removeCamera(parsed.data))
//             return parsed;
//         }]
//     });
// }

const methods = {
    getViews,
    createView,
    linkCamerasToView,
    updateView
}

export default methods;
