import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    companies: [],
}

export const companySlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {
        setCompanies: (state, action) => {
            state.companies = action.payload
        },
        addCompany: (state, action) => {
            state.companies.push(action.payload)
        },
        updateSingleCompany: (state, action) => {
            const companieToUpdateIndex = state.companies.findIndex(companie => companie.id === action.payload.id)
            state.companies[companieToUpdateIndex] = action.payload
        },
        removeCompany: (state, action) => {
            const companieToDeleteIndex = state.companies.findIndex(companie => companie.id === action.payload.id)
            state.companies.splice(companieToDeleteIndex, 1)
        },
        deleteCompanies: (state) => {
            state.companies = []
        },
    },
})

export const { setCompanies, addCompany, updateSingleCompany, removeCompany, deleteCompanies } = companySlice.actions

export default companySlice.reducer