import { setDimensions, setWidth, setHeight } from '../features/screen/screenSlice.js';
import { store } from "../store";

const setSreenDimensions = (dimensions) => {
    store.dispatch(setDimensions(dimensions))
}

const setSreenWidth = (width) => {
    store.dispatch(setWidth(width))
}

const setSreenHeight = (height) => {
    store.dispatch(setHeight(height))
}

const methods = {
    setSreenDimensions,
    setSreenWidth,
    setSreenHeight,
}

export default methods;
