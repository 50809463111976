// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-card_card-container__LNudC {
    width: 100%;
    max-width: 793px;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 30px;
}

.event-card_card-image-container__-GRG5 {
    height: 350px;
    width: 100%;
    overflow-y: hidden;
}

.event-card_card-image__CVEzh {
    height: 100%;
    width: auto;
}

.event-card_card-description__Nv1zd {
    min-height: 75px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: var(--color-secondary-typography);
    background-color: var(--color-content-sidebar);
    padding: 20px;
}

.event-card_card-description-info__ad4F5 {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
    
}

.event-card_card-description-main-text__9C-kL {
    font-size: 18px;
    font-weight: 700
}

.event-card_card-description-secondary-text__fZoJs {
    font-size: 14px;
    font-weight: 450
}

.event-card_start-button__tVVWK {
    border: none;
    background: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/event-card/event-card.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;IAC9B,wCAAwC;IACxC,8CAA8C;IAC9C,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,8BAA8B;;AAElC;;AAEA;IACI,eAAe;IACf;AACJ;;AAEA;IACI,eAAe;IACf;AACJ;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[".card-container {\n    width: 100%;\n    max-width: 793px;\n    border-radius: 5px;\n    overflow: hidden;\n    margin-bottom: 30px;\n}\n\n.card-image-container {\n    height: 350px;\n    width: 100%;\n    overflow-y: hidden;\n}\n\n.card-image {\n    height: 100%;\n    width: auto;\n}\n\n.card-description {\n    min-height: 75px;\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    color: var(--color-secondary-typography);\n    background-color: var(--color-content-sidebar);\n    padding: 20px;\n}\n\n.card-description-info {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: left;\n    justify-content: space-between;\n    \n}\n\n.card-description-main-text {\n    font-size: 18px;\n    font-weight: 700\n}\n\n.card-description-secondary-text {\n    font-size: 14px;\n    font-weight: 450\n}\n\n.start-button {\n    border: none;\n    background: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card-container": `event-card_card-container__LNudC`,
	"card-image-container": `event-card_card-image-container__-GRG5`,
	"card-image": `event-card_card-image__CVEzh`,
	"card-description": `event-card_card-description__Nv1zd`,
	"card-description-info": `event-card_card-description-info__ad4F5`,
	"card-description-main-text": `event-card_card-description-main-text__9C-kL`,
	"card-description-secondary-text": `event-card_card-description-secondary-text__fZoJs`,
	"start-button": `event-card_start-button__tVVWK`
};
export default ___CSS_LOADER_EXPORT___;
