import React from 'react';
import ReactHlsPlayer from 'react-hls-player';

export const HLSVideoStream = ({url}) => {
  const playerRef = React.useRef();

  function playVideo() {
    playerRef.current.play();
  }

  function pauseVideo() {
    playerRef.current.pause();
  }

  function toggleControls() {
    playerRef.current.controls = !playerRef.current.controls;
  }

  return (
    <ReactHlsPlayer
      playerRef={playerRef}
      src={url}
      autoPlay={true}
      width="100%"
      height="auto"
      hlsConfig={{
        lowLatencyMode: true,
        backBufferLength: Infinity,
        capLevelToPlayerSize: true,
      }}
    />
  );
}
