// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-tabs_menu-tabs__K4DCn {
    display: flex;
    flex-direction: row;
    gap: 0;
}

.menu-tabs_tab-item__p5y\\+Q {
    border: none;
    background: transparent;
    padding: 5px 10px;
    color: var(--color-secondary-typography);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: -moz-fit-content;
    width: fit-content;
    white-space: nowrap;
}

.menu-tabs_tab-item-active__cOURu {
    border-bottom: 2px solid var(--color-content-highlight);
    color: var(--color-content-highlight)
}

@media screen and (max-width: 1000px) {
    .menu-tabs_menu-tabs__K4DCn {
        width: 100%;
        overflow: scroll;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }

    .menu-tabs_menu-tabs__K4DCn::-webkit-scrollbar  {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/menu-tabs/menu-tabs.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,MAAM;AACV;;AAEA;IACI,YAAY;IACZ,uBAAuB;IACvB,iBAAiB;IACjB,wCAAwC;IACxC,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;IACvB,QAAQ;IACR,uBAAkB;IAAlB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,uDAAuD;IACvD;AACJ;;AAEA;IACI;QACI,WAAW;QACX,gBAAgB;QAChB,wBAAwB,GAAG,0BAA0B;QACrD,qBAAqB,GAAG,YAAY;IACxC;;IAEA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".menu-tabs {\n    display: flex;\n    flex-direction: row;\n    gap: 0;\n}\n\n.tab-item {\n    border: none;\n    background: transparent;\n    padding: 5px 10px;\n    color: var(--color-secondary-typography);\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n    gap: 4px;\n    width: fit-content;\n    white-space: nowrap;\n}\n\n.tab-item-active {\n    border-bottom: 2px solid var(--color-content-highlight);\n    color: var(--color-content-highlight)\n}\n\n@media screen and (max-width: 1000px) {\n    .menu-tabs {\n        width: 100%;\n        overflow: scroll;\n        -ms-overflow-style: none;  /* Internet Explorer 10+ */\n        scrollbar-width: none;  /* Firefox */\n    }\n\n    .menu-tabs::-webkit-scrollbar  {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu-tabs": `menu-tabs_menu-tabs__K4DCn`,
	"tab-item": `menu-tabs_tab-item__p5y+Q`,
	"tab-item-active": `menu-tabs_tab-item-active__cOURu`
};
export default ___CSS_LOADER_EXPORT___;
