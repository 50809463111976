import React, { useState, useRef, useEffect, useCallback } from 'react';
import '../../assets/css/view.css';
import { useTranslation } from 'react-i18next';
import addImage from '../../assets/image/add.png';
import cameraImage from '../../assets/image/camera.png';
import defaultImage from '../../assets/image/default.png';
import scenarioService from '../../services/scenario.service'
import ruleService from '../../services/rule.service'
import { Error } from '../error/error';
import { FormTextField } from '../form-textfield/form-textfield';
import classNames from 'classnames';
import { Select } from '../select/select';
import { EVENT_TYPES } from '../../utils/constants';
import { convertBase64 } from '../../utils/base64Converter';
import {ReactComponent as PictureIcon} from '../../assets/icons/picture.svg'
import { Vision } from './vision';
import * as yup from 'yup'

export const AddZoneRuleFormContent = ({scenarioId, handleClose, ruleName, ...props}) => {
    const {t} = useTranslation("common")
    const fileRef = useRef()
    const [formFields, setFormFields] = useState(false)
    const [validation, setValidation] = useState({name: true})

    const [fileName, setFileName] = useState('')
    const [layers, setLayers] = useState([])

    const formSchema = yup.object().shape({
        eventType: yup.string().required(),
        file: yup.string()
    })

    const [values, setValues] = useState({
        eventType: 'auto_num',
        file: ''
    })
    const [errors, setErrors] = useState({
        eventType: false
    })

    const onFieldChange = useCallback((fieldName, value) => {
        console.log(fieldName, value)
        setValues((prev) => {
            prev[fieldName] = value
            console.log(prev)
            return {...prev}
        })
    }, [])

    const handleFileRead = async (event) => {
        const file = event.target.files[0]
        const base64 = await convertBase64(file)
        console.log(base64)
        setFileName(fileRef.current.files[0].name)
        onFieldChange('file', base64)
    }

    const eventTypes = Object.entries(EVENT_TYPES).map(([value, caption]) => {
        return {
            caption: t(caption),
            value
        }
    })

    const onSubmit = useCallback(
        async (event) => {
          event.preventDefault()
    
          const isFormValid = await formSchema.isValid(values, {
            abortEarly: false,
          })
    
          if (isFormValid) {
            console.log("CHECK VALUES FILE", values)
            const payload = {
                "name": ruleName,
                "status": String(scenarioId),
                "property" : JSON.stringify({
                    type: 'event',
                    parameter: values.eventType,
                    payload: ''
                }),
                "is_on" : true
            }
            console.log("CHECK PAYLOAD", payload)
            try {
                const result = await ruleService.createRule(payload);
                console.log("CHECK RESPONSE", result)
                if (result.data) {
                    handleClose()
                }
            } catch (error) {
                console.log(JSON.stringify(error));
            }
          } else {
            formSchema.validate(values, { abortEarly: false }).catch((err) => {
              const errors = err.inner.reduce((acc, error) => {
                return {
                  ...acc,
                  [error.path]: true,
                }
              }, {})
    
              setErrors((prevErrors) => {
                return {
                    ...prevErrors,
                    ...errors
                }
              })
            })
          }
        },
        [values]
    )

    return (
        <>
            <Vision ruleName={ruleName} scenarioId={scenarioId} />
        </>
    )
}