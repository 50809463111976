import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    dark: false,
}

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setDarkTheme: (state) => {
            state.dark = true
        },
        setLightTheme: (state) => {
            state.dark = false
        },
        changeTheme: (state) => {
            state.dark = !state.dark
        },
    },
})

export const { setDarkTheme, setLightTheme, changeTheme, } = themeSlice.actions

export default themeSlice.reducer