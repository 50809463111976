import { useRef } from 'react';
import { useSelector } from 'react-redux';
import Head from 'next/head';
import { useParams, useNavigate } from 'react-router-dom';
import { Button as CustomButton } from '../../components/button/button';
import { TextField } from '../../components/textfield/textfield';
import { MainSidebar } from '../../components/main-sidebar/main-sidebar';
import { ContentSidebar } from '../../components/content-sidebar/content-sidebar';
import { ContentItem } from '../../components/content-item/content-item';

import { Search } from '../../components/search/search';
import { Filters } from '../../components/filters/filters';

import ContentSideNav from '../../components/content-side-nav/content-side-nav';
import CameraListItem from '../../components/content-list-item/content-list-item';
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import {useTranslation} from "react-i18next";
import { MobileHeader } from '../../components/mobile-header/mobile-header';
import { MenuTabs } from '../../components/menu-tabs/menu-tabs';
import cameraIcon from '../../assets/icons/camerasItemIcon.svg';
import AddScenarioPopup from './add-scenario-popup';
import scenarioService from '../../services/scenario.service'
import ruleService from '../../services/rule.service'

import { ReactComponent as BackIcon } from '../../assets/icons/left.svg'
import { Vision } from './vision';

import editIcon from '../../assets/icons/edit.svg'
import styles from './automation.module.css'
import { RulesTable } from '../../components/rules-table/rules-table';
import AddRulePopup from './add-rule-popup';

const Automation = () => {
  const {t} = useTranslation("common")
  const navigate = useNavigate()
  const currentScenarioId = useParams().scenarioId

  const currentScenarioData = () => {
    if(currentScenarioId) {
      return scenarios.find(server => String(server.id) === currentScenarioId)
    }
    return {}
  }

  const [formOpen, setFormOpen] = useState(false);
  const [formRuleOpen, setFormRuleOpen] = useState(false);
  const handleOpen = () => setFormOpen(true);
  const handleClose = () => setFormOpen(false);

  const scenarios = useSelector((state) => state.scenarios.scenarios);
  /*const scenarios = [
    {
      "id":1,
      "company_id":0,
      "status" : "on",
      "kol_vo":1,
      "kol_vo_dop":0,
      "link":"https://github.com/",
      "login":"login",
      "params":"params",
      "comment":"главный",
      "name":"Поиск номера авто",
      "rules": [
        {
          "name" : "Поиск номера авто",
          "is_on" : true,
          "type" : "schedule",
          "parameter" : "Определение номера авто"
        },
        {
          "name" : "Поиск номера авто",
          "is_on" : false,
          "type" : "event",
          "parameter" : "Определение номера авто"
        },
      ]
    }
  ]*/

  /*const rules = [
    {
      "name" : "Поиск номера авто",
      "is_on" : true,
      "type" : "schedule",
      "parameter" : "Определение номера авто"
    },
    {
      "name" : "Поиск номера авто",
      "is_on" : false,
      "type" : "event",
      "parameter" : "Определение номера авто"
    },
  ]*/

  const rules = useSelector((state) => state.rules.rules)
  const ruleDelete = (id) => {
    ruleService.deleteRule({id: id})
  }

  const screen = useSelector((state) => state.screen.screen);

  const [urlChangeDisabled, setUrlChangeDisabled] = useState(true)
  const [serverUrl, setServerUrl] = useState('')

  const [value, setValue] = useState(0);

  const [scenarioTab, setScenarioTab] = useState('rules')
  const [showSidebar, setShowSidebar] = useState(true)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const sidebarMainMenu = useRef(null)
  const [openSidebarMainMenu, setOpenSidebarMainMenu] = useState(screen.width > 1000 ? true : false);
  const [desktopOpenMainSidebarMenu, setDesktopOpenMainSidebarMenu] = useState(true)

  const [searchText, setSearchText] = useState('')
  const [searchRuleText, setSearchRuleText] = useState('')

  const [filter, setFilter] = useState('on')

  const filters = [
    {
      caption: t('cameras.filter.all'),
      value: 'all',
      onChange: () => {setFilter('all')}
    },
    {
      caption: t('cameras.filter.online'),
      value: 'online',
      onChange: () => {setFilter('online')}
    },
    {
      caption: t('cameras.filter.offline'),
      value: 'offline',
      onChange: () => {setFilter('offline')}
    },
  ]

  const searchFilter = (string, substring) => {
    if (substring !== '') {
      return string.includes(substring)
    }
    return true
  }

  const mapConfig = {
    lat: 22,
    lng: -72,
    zoom: 6
  };

  // RULE DELETION FINISH

  const tabs = [
    {
      caption: t('automation.rulesAndNotifications'),
      value: 'rules',
      onClick: () => {setScenarioTab('rules')}
    },
    {
      caption: t('automation.cameras'),
      value: 'cameras',
      onClick: () => {setScenarioTab('cameras')}
    },
    {
      caption: t('automation.events'),
      value: 'events',
      onClick: () => {setScenarioTab('events')}
    },
  ]

  useEffect(() => {
    scenarioService.getScenarios()
  }, [])

  useEffect(() => {
    console.log("CHECK RULES", rules)
    ruleService.getRules(Number(currentScenarioId))
  }, [currentScenarioId])

  return (
    <>
    {formOpen && <AddScenarioPopup onClose={handleClose}/>}
    {formRuleOpen && <AddRulePopup handleClose={() => setFormRuleOpen(false)} scenarioId={Number(currentScenarioId)} />}
    <div ref={sidebarMainMenu}>
      <MainSidebar show={screen.width < 1000 ? openSidebarMainMenu : desktopOpenMainSidebarMenu} active={'/automation'} onClose={() => {setOpenSidebarMainMenu(false)}}/>
    </div>
    <div className={styles['mainWrapper']}>
      {
          showSidebar &&
          (
            <ContentSidebar heading={t('automation.automation')} mobileSidebarOpen={() => {setOpenSidebarMainMenu(true)}} isButtonEnabled={true} onButtonClick={handleOpen} onClose={() => {setShowSidebar(prev => !prev)}}>
            <div className={styles['search-controls-container']}>
              <div className={styles['search-field-container']}>
                <Search value={searchText} onChange={(e) => {setSearchText(e.target.value)}}/>
              </div>
              <div className={styles['search-filter-container']}>
                <Filters items={filters} chosen={filter}/>
              </div>
            </div>
              {
                scenarios?.filter((scenario, index) => searchFilter(scenario.name, searchText) || searchFilter(String(scenario.id), searchText)).map(scenario => (<ContentItem item={scenario} index onClick={() => { if (screen.width < 1000) setShowSidebar(false); navigate('/automation/' + String(scenario.id))}} active={currentScenarioId === String(scenario.id)} icon={cameraIcon} />))
              }
            </ContentSidebar>
          )
      }
      <div className={styles['rightBlock']}>
        {
            screen.width < 1000 &&
            (
              <MobileHeader heading={scenarios.find(camera => String(camera.id) === currentScenarioId)?.name} leftIcon={<BackIcon />} leftOnclick={() => {setShowSidebar(prev => !prev)}}/>
            )
        }
        <div className={styles['content-container']}>
          {
            currentScenarioId && (
              <>
              {
                screen.width > 1000 &&
                (
                  <div className={styles['content-heading']}>{scenarios.find(camera => String(camera.id) === currentScenarioId)?.name}</div>
                )
              }
              <MenuTabs tabs={tabs} active={scenarioTab} />
              {
                scenarioTab === 'rules' &&
                <div className={styles['tab-content-container']}>
                  <div className={styles['search-controls']}>
                    <div className={styles['search-container']}>
                      <Search value={searchRuleText} onChange={(e) => {setSearchRuleText(e.target.value)}} clearIcon={true} bordered={true}/>
                    </div>
                    <button className="rightBlockAddCameraBtn" onClick={()=>{setFormRuleOpen(true)}}>
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 3.75V14.25M3.75 9H14.25" stroke="#5E5873" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      {t('automation.newRule')}
                    </button>
                  </div>
                  <RulesTable data={rules[currentScenarioId]?.filter((rule, index) => searchFilter(rule.name, searchRuleText)).map((rule) => { return {...rule, onDelete: () => {ruleDelete(rule.id)}}})}/>
                </div>
              }

              {
                scenarioTab === 'cameras' &&
                <div className={styles['tab-content-container']}>
                  2
                </div>
              }
              
              {
                scenarioTab === 'events' &&
                <div className={styles['tab-content-container']}>
                  3
                </div>
              }
              </>
            )
          }
        </div>
      </div>
    </div>
  </>
);
}



export default Automation;
