import React, { useState, useRef, useEffect, useCallback } from 'react';
import '../../assets/css/view.css';
import { useTranslation } from 'react-i18next';
import addImage from '../../assets/image/add.png';
import cameraImage from '../../assets/image/camera.png';
import defaultImage from '../../assets/image/default.png';
import authService from '../../services/auth.service'
import { Error } from '../error/error';
import { FormTextField } from '../form-textfield/form-textfield';
import classNames from 'classnames';
import styles from './auth-form.module.css'
import { TextField } from '../textfield/textfield';
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../spinner/spinner';

export const AuthFormRegister = ({onFinish, ...props}) => {
    const {t} = useTranslation("common")
    const navigate = useNavigate();
    const [formFields, setFormFields] = useState(false)
    const [validation, setValidation] = useState({streamUrl: true, name: true, username: true, password: true, snapshotUrl: true})

    const [loading, setLoading] = useState(false)

    const formSchema = yup.object().shape({
        login: yup.string().required(),
        password: yup.string().required(),
        companyName: yup.string().required(),
    })

    const [values, setValues] = useState({
        login: '',
        password: '',
        companyName: ''
    })
    const [errors, setErrors] = useState({
        login: false,
        password: false,
        companyName: false
    })

    const onFieldChange = useCallback((fieldName, value) => {
        setValues((prev) => {
            prev[fieldName] = value
            console.log(prev)
            return {...prev}
        })
    }, [])

    const handleValidSubmit = useCallback(
        async (event) => {
          event.preventDefault()
    
          const isFormValid = await formSchema.isValid(values, {
            abortEarly: false,
          })
    
          if (isFormValid) {
            const payload = {
                "login": values.login,
                "password": values.password,
                "name": values.companyName
            }
            try {
                setLoading(true)
                const result = await authService.register(payload);
                console.log(result)
                if (result.data) {
                    onFinish()
                    navigate('/')
                }
            } catch (error) {
                console.log(JSON.stringify(error));
            }
          } else {
            formSchema.validate(values, { abortEarly: false }).catch((err) => {
              const errors = err.inner.reduce((acc, error) => {
                return {
                  ...acc,
                  [error.path]: true,
                }
              }, {})
    
              setErrors((prevErrors) => {
                return {
                    ...prevErrors,
                    ...errors
                }
              })
            })
          }
        },
        [values]
    )

    return (
        <>
            <div className="inputWrapper">
                <label htmlFor="cameraName">
                    {t('login.companyName')}
                </label>
                <FormTextField value={values.companyName} error={errors.companyName} onInput={useCallback((event) => {onFieldChange('companyName', event.target.value)},[onFieldChange])} />
                {errors.companyName && <Error>{t('form.emptyFieldError')}</Error>}
            </div>

            <div className="inputWrapper">
                <label htmlFor="cameraName">
                    {t('login.login')}
                </label>
                <FormTextField value={values.login} error={errors.login} onInput={useCallback((event) => {onFieldChange('login', event.target.value)},[onFieldChange])} />
                {errors.login && <Error>{t('form.emptyFieldError')}</Error>}
            </div>

            <div className="inputWrapper">
                <label htmlFor="cameraName">
                    {t('login.password')}
                </label>
                <FormTextField  type={'password'} error={errors.password} value={values.password} onInput={useCallback((event) => {onFieldChange('password', event.target.value)},[onFieldChange])} />
                {errors.password && <Error>{t('form.emptyFieldError')}</Error>}
            </div>

            <button className={styles['form-button']} onClick={handleValidSubmit}>
                {
                    loading ?
                    <Spinner /> :
                    t('auth.register')
                }
            </button>
            <div className={styles['auth-message']}>
                {
                    t('auth.registerMessage')
                }
            </div>
        </>
    )
}