// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-textfield_textfield__dX4VJ {
    height: 38px;
    border: 1px solid #E6E7EB;
    border-radius: 5px;
    outline: none;
    padding: 8px 15px 8px 15px;
    width: 100%
}

.form-textfield_error-textfield__j1efP {
    border: 1px solid var(--color-error-input-border);
    background-color: var(--color-error-input-background);
}

.form-textfield_textfield__dX4VJ:focus {
    outline: none
}

.form-textfield_textfield__dX4VJ::placeholder {
    color: #8E99A0
}

.form-textfield_textfield__dX4VJ::-ms-input-placeholder {
    color: #8E99A0;
}

.form-textfield_input-wrapper__oNW4f {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-textfield_field-icon__Dlrk7 {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 10px;
    height: 100%;
    width: 38px;
    z-index: 10;

    display: flex;
    align-items: center;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/form-textfield/form-textfield.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,0BAA0B;IAC1B;AACJ;;AAEA;IACI,iDAAiD;IACjD,qDAAqD;AACzD;;AAEA;IACI;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,SAAS;IACT,cAAc;IACd,WAAW;IACX,YAAY;IACZ,WAAW;IACX,WAAW;;IAEX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".textfield {\n    height: 38px;\n    border: 1px solid #E6E7EB;\n    border-radius: 5px;\n    outline: none;\n    padding: 8px 15px 8px 15px;\n    width: 100%\n}\n\n.error-textfield {\n    border: 1px solid var(--color-error-input-border);\n    background-color: var(--color-error-input-background);\n}\n\n.textfield:focus {\n    outline: none\n}\n\n.textfield::placeholder {\n    color: #8E99A0\n}\n\n.textfield::-ms-input-placeholder {\n    color: #8E99A0;\n}\n\n.input-wrapper {\n    position: relative;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.field-icon {\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    margin: auto 0;\n    right: 10px;\n    height: 100%;\n    width: 38px;\n    z-index: 10;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textfield": `form-textfield_textfield__dX4VJ`,
	"error-textfield": `form-textfield_error-textfield__j1efP`,
	"input-wrapper": `form-textfield_input-wrapper__oNW4f`,
	"field-icon": `form-textfield_field-icon__Dlrk7`
};
export default ___CSS_LOADER_EXPORT___;
