// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-side-nav_nav-container__0Z\\+SR {
    background-color: var(--color-content-sidebar);
    color: var(--color-main-typography);
    min-height: 100vh;
    padding-left: 30px;
    padding-top: 36px;
}`, "",{"version":3,"sources":["webpack://./src/components/content-side-nav/content-side-nav.module.css"],"names":[],"mappings":"AAAA;IACI,8CAA8C;IAC9C,mCAAmC;IACnC,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":[".nav-container {\n    background-color: var(--color-content-sidebar);\n    color: var(--color-main-typography);\n    min-height: 100vh;\n    padding-left: 30px;\n    padding-top: 36px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav-container": `content-side-nav_nav-container__0Z+SR`
};
export default ___CSS_LOADER_EXPORT___;
