// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-list-item_camera-item__0koCT {
    background-color: var(--color-camera-item);
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.content-list-item_camera-id-row__1B9C1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5px
}

.content-list-item_camera-name__V-TA2 {
    color: var(--color-secondary-typography);
    margin-top: 10px;
    font-weight: 450;
    font-size: 18px
}

.content-list-item_camera-id__ASkMe {
    color: var(--color-highlighted-typography);
    margin-top: 2px;
    font-weight: 450;
    font-size: 18px
}
`, "",{"version":3,"sources":["webpack://./src/components/content-list-item/content-list-item.module.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,2BAA2B;IAC3B;AACJ;;AAEA;IACI,wCAAwC;IACxC,gBAAgB;IAChB,gBAAgB;IAChB;AACJ;;AAEA;IACI,0CAA0C;IAC1C,eAAe;IACf,gBAAgB;IAChB;AACJ","sourcesContent":[".camera-item {\n    background-color: var(--color-camera-item);\n    width: 100%;\n    height: 100%;\n    border-radius: 5px;\n}\n\n.camera-id-row {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: flex-start;\n    gap: 5px\n}\n\n.camera-name {\n    color: var(--color-secondary-typography);\n    margin-top: 10px;\n    font-weight: 450;\n    font-size: 18px\n}\n\n.camera-id {\n    color: var(--color-highlighted-typography);\n    margin-top: 2px;\n    font-weight: 450;\n    font-size: 18px\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"camera-item": `content-list-item_camera-item__0koCT`,
	"camera-id-row": `content-list-item_camera-id-row__1B9C1`,
	"camera-name": `content-list-item_camera-name__V-TA2`,
	"camera-id": `content-list-item_camera-id__ASkMe`
};
export default ___CSS_LOADER_EXPORT___;
