import styles from './error.module.css'

export const Error = ({children, ...props}) => {

  return (
    <span className={styles['error']} {...props}>
      {children}
    </span>
  );
}
