// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filters_filter-button-container__eR3g0 {
    position: relative;
}

.filters_filter-button__BMA6O{
    width: 44px;
    height: 44px;
    background-color: var(--color-inactive-filter);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.filters_filter-button-active__0AB4u {
    background-color: var(--color-content);
}

.filters_filter-dropdown__kirv1 {
    position: absolute;
    top: 110%;
    width: 113px;
    z-index: 10;
    background-color: var(--color-content);
    border-radius: 5px;
    overflow: hidden;
    right: 0
}

.filters_filter-dropdown-item__LRFTJ {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-size: 14px;
    font-weight: 450;
    padding-left: 15px;
    border-top: 1px solid var(--color-inactive-filter);
    color: var(--color-main-typography)
}

.filters_filter-dropdown-item-first__4P7oh {
    border-top: none
}`, "",{"version":3,"sources":["webpack://./src/components/filters/filters.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,8CAA8C;IAC9C,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,YAAY;IACZ,WAAW;IACX,sCAAsC;IACtC,kBAAkB;IAClB,gBAAgB;IAChB;AACJ;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,SAAS;IACT,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,kDAAkD;IAClD;AACJ;;AAEA;IACI;AACJ","sourcesContent":[".filter-button-container {\n    position: relative;\n}\n\n.filter-button{\n    width: 44px;\n    height: 44px;\n    background-color: var(--color-inactive-filter);\n    border: none;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 5px;\n}\n\n.filter-button-active {\n    background-color: var(--color-content);\n}\n\n.filter-dropdown {\n    position: absolute;\n    top: 110%;\n    width: 113px;\n    z-index: 10;\n    background-color: var(--color-content);\n    border-radius: 5px;\n    overflow: hidden;\n    right: 0\n}\n\n.filter-dropdown-item {\n    height: 48px;\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    gap: 10px;\n    font-size: 14px;\n    font-weight: 450;\n    padding-left: 15px;\n    border-top: 1px solid var(--color-inactive-filter);\n    color: var(--color-main-typography)\n}\n\n.filter-dropdown-item-first {\n    border-top: none\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter-button-container": `filters_filter-button-container__eR3g0`,
	"filter-button": `filters_filter-button__BMA6O`,
	"filter-button-active": `filters_filter-button-active__0AB4u`,
	"filter-dropdown": `filters_filter-dropdown__kirv1`,
	"filter-dropdown-item": `filters_filter-dropdown-item__LRFTJ`,
	"filter-dropdown-item-first": `filters_filter-dropdown-item-first__4P7oh`
};
export default ___CSS_LOADER_EXPORT___;
