import React, { useState, useRef, useEffect, useCallback } from 'react';
import '../../assets/css/view.css';
import { useTranslation } from 'react-i18next';
import addImage from '../../assets/image/add.png';
import cameraImage from '../../assets/image/camera.png';
import defaultImage from '../../assets/image/default.png';
import cameraService from '../../services/cameras.service';
import viewService from '../../services/views.service';
import notificationService from '../../services/notification.service'
import { Error } from '../../components/error/error';
import { FormTextField } from '../../components/form-textfield/form-textfield';
import classNames from 'classnames';
import * as yup from 'yup'
import { Spinner } from '../spinner/spinner';
import { Link } from 'react-router-dom';

export const AddCameraFormContent = ({handleClose, view, ...props}) => {
    const {t} = useTranslation("common")
    const [formFields, setFormFields] = useState(false)
    const [validation, setValidation] = useState({streamUrl: true, name: true, username: true, password: true, snapshotUrl: true})

    const [loading, setLoading] = useState(false)
    const [loadingUrl, setLoadingUrl] = useState(false)
    const [properUrl, setProperUrl] = useState(false)
    const [checkedUrl, setCheckedUrl] = useState(false)
    const [exisitngCamera, setExistingCamera] = useState(null)

    const formSchema = yup.object().shape({
        name: yup.string().required(),
        username: yup.string().required(),
        password: yup.string().required(),
        snapshotUrl: yup.string().url().required(),
        streamUrl: yup.string().required(),
    })

    const [values, setValues] = useState({
        name: '',
        username: '',
        password: '',
        snapshotUrl: '',
        streamUrl: '',
    })
    const [errors, setErrors] = useState({
        name: false,
        username: false,
        password: false,
        snapshotUrl: false,
        streamUrl: false,
    })

    const onFieldChange = useCallback((fieldName, value) => {
        console.log(fieldName, value)
        setValues((prev) => {
            prev[fieldName] = value
            console.log(prev)
            return {...prev}
        })
    }, [])

    const onUrlCheck = useCallback(
        async (event) => {
          event.preventDefault()
    
          if (values.streamUrl) {
            const payload = {
                "link": values.streamUrl,
            }
            try {
                setLoadingUrl(true)
                console.log('check')
                const result = await cameraService.checkCamera(payload);
                console.log("CHECK DATA", result)
                if (result.data.data) {
                    setProperUrl(true)
                    setCheckedUrl(true)
                    setLoadingUrl(false)
                } else {
                    const allCameras = await cameraService.getCameras()
                    console.log("CHECK CAMERAS", allCameras)
                    const exisitngCamera = allCameras.data.find(camera => camera.link === values.streamUrl)
                    if(exisitngCamera) {
                        setExistingCamera(exisitngCamera)
                    }
                    setProperUrl(false)
                    setCheckedUrl(true)
                    setLoadingUrl(false)
                }
            } catch (error) {
                console.log(JSON.stringify(error));
            }
          } else {
            formSchema.validate(values, { abortEarly: false }).catch((err) => {
              const errors = err.inner.reduce((acc, error) => {
                return {
                  ...acc,
                  [error.path]: true,
                }
              }, {})
    
              setErrors((prevErrors) => {
                return {
                    ...prevErrors,
                    ...errors
                }
              })
            })
          }
        },
        [values]
    )

    const onSubmit = useCallback(
        async (event) => {
          event.preventDefault()
    
          const isFormValid = await formSchema.isValid(values, {
            abortEarly: false,
          })
    
          if (isFormValid) {
            const payload = {
                "link": values.streamUrl,
                "name": values.name,
                "login": values.username,
                "password": values.password,
            }
            try {
                setLoading(true)
                const result = await cameraService.createCamera(payload);
                console.log("CHECK DATA", result)
                if (result.data) {
                    if(view) {
                        try {
                            console.log(result)
                            const cameraList = JSON.parse(view.list)
                            cameraList.push(result.data.data.id)
                            const res = await viewService.updateView({...view, list: JSON.stringify(cameraList)})
                            console.log("CHECK VIEW UPDATE", res)
                        } catch(error) {
                            console.log(error)
                        }
                    }
                    try {
                        const timestamp = new Date().toUTCString()
                        const res = await notificationService.createNotification({
                            "text": values.name + ' | ID:' + String(result.data.data.id),
                            "create_at": timestamp,
                            "info_subject":"Камера " + String(result.data.data.id) + " была создана",
                        })
                    } catch(error) {
                        console.log(error)
                    }
                    handleClose()
                }
            } catch (error) {
                console.log(JSON.stringify(error));
            }
          } else {
            formSchema.validate(values, { abortEarly: false }).catch((err) => {
              const errors = err.inner.reduce((acc, error) => {
                return {
                  ...acc,
                  [error.path]: true,
                }
              }, {})
    
              setErrors((prevErrors) => {
                return {
                    ...prevErrors,
                    ...errors
                }
              })
            })
          }
        },
        [values]
    )

    return (
        <div className={'createNewCameraWrapper'}>

            <div className={'createNewCameraBody'}>
                <span className={'createNewCameraBodyTitle'}>{t('cameras.addNewCamera')}</span>

                <div className="inputWrapper">
                    <div className={'labelWrapper'}>
                        <label htmlFor="streamURL">
                            {t('cameras.streamUrl')}
                        </label>
                        <svg className={'helpIcon'} width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.30283 5.74984C5.43997 5.35998 5.71066 5.03123 6.06697 4.82183C6.42327 4.61243 6.84218 4.53588 7.24951 4.60575C7.65684 4.67562 8.0263 4.88739 8.29245 5.20356C8.55861 5.51973 8.70427 5.91989 8.70366 6.33317C8.70366 7.49984 6.95366 8.08317 6.95366 8.08317M7.00033 10.4165H7.00616M12.8337 7.49984C12.8337 10.7215 10.222 13.3332 7.00033 13.3332C3.77866 13.3332 1.16699 10.7215 1.16699 7.49984C1.16699 4.27818 3.77866 1.6665 7.00033 1.6665C10.222 1.6665 12.8337 4.27818 12.8337 7.49984Z" stroke="#5D8EA4" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <input disabled={properUrl} className={classNames('popup-input', {'error-input': errors.streamUrl})} value={values.streamUrl} placeholder={t('cameras.streamUrl')} onChange={useCallback((event) => {onFieldChange('streamUrl', event.target.value)},[onFieldChange])}/>
                    {errors.streamUrl && <Error>{t('form.emptyFieldError')}</Error>}
                </div>
                {
                    !properUrl && !checkedUrl &&
                    <span style={{fontSize: '12px'}}>{t('cameras.checkUrlMessage')}</span>
                }

                {
                    !properUrl && exisitngCamera &&
                    <span style={{fontSize: '12px'}}>{t('cameras.urlTakenByCamera')} <Link onClick={handleClose} to={'/cameras/' + exisitngCamera.id}>{exisitngCamera.name}</Link></span>
                }

                {
                    properUrl &&
                    <span style={{color: "var(--color-status-green)", fontSize: '12px'}}>{t('cameras.urlValid')}</span>
                }

                {
                    !properUrl && checkedUrl && !exisitngCamera &&
                    <span style={{color: "var(--color-status-red)", fontSize: '12px'}}>{t('cameras.urlNotValid')}</span>
                }

                {
                    properUrl &&
                    (
                        <>
                        <div className="inputWrapper" style={{marginTop: '30px'}}>
                            <label htmlFor="cameraName">
                                {t('cameras.cameraName')}
                            </label>
                            <input className={classNames('popup-input', {'error-input': errors.name})} value={values.name} placeholder={t('cameras.cameraName')} onInput={(event) => {console.log(event.target.value); onFieldChange('name', event.target.value)}}/>
                            {errors.name && <Error>{t('form.emptyFieldError')}</Error>}
                        </div>

                        <div className="inputWrapper">
                            <div className={'labelWrapper'}>
                                <label htmlFor="snapshotURL">
                                    {t('cameras.snapshotUrl')}
                                </label>
                                <svg className={'helpIcon'} width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.30283 5.74984C5.43997 5.35998 5.71066 5.03123 6.06697 4.82183C6.42327 4.61243 6.84218 4.53588 7.24951 4.60575C7.65684 4.67562 8.0263 4.88739 8.29245 5.20356C8.55861 5.51973 8.70427 5.91989 8.70366 6.33317C8.70366 7.49984 6.95366 8.08317 6.95366 8.08317M7.00033 10.4165H7.00616M12.8337 7.49984C12.8337 10.7215 10.222 13.3332 7.00033 13.3332C3.77866 13.3332 1.16699 10.7215 1.16699 7.49984C1.16699 4.27818 3.77866 1.6665 7.00033 1.6665C10.222 1.6665 12.8337 4.27818 12.8337 7.49984Z" stroke="#5D8EA4" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <input className={classNames('popup-input', {'error-input': errors.snapshotUrl})} value={values.snapshotUrl} placeholder={t('cameras.snapshotUrl')} onChange={(event) => {onFieldChange('snapshotUrl', event.target.value)}}/>
                            {errors.snapshotUrl && <Error>{t('form.emptyFieldError')}</Error>}
                        </div>

                        <div className="inputWrapper">
                            <div className={'labelWrapper'}>

                                <label htmlFor="Username">
                                    {t('cameras.credentials')}
                                </label>
                                <svg className={'helpIcon'} width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.30283 5.74984C5.43997 5.35998 5.71066 5.03123 6.06697 4.82183C6.42327 4.61243 6.84218 4.53588 7.24951 4.60575C7.65684 4.67562 8.0263 4.88739 8.29245 5.20356C8.55861 5.51973 8.70427 5.91989 8.70366 6.33317C8.70366 7.49984 6.95366 8.08317 6.95366 8.08317M7.00033 10.4165H7.00616M12.8337 7.49984C12.8337 10.7215 10.222 13.3332 7.00033 13.3332C3.77866 13.3332 1.16699 10.7215 1.16699 7.49984C1.16699 4.27818 3.77866 1.6665 7.00033 1.6665C10.222 1.6665 12.8337 4.27818 12.8337 7.49984Z" stroke="#5D8EA4" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>

                            <input className={classNames('popup-input', {'error-input': errors.username})} style={{marginBottom:'10px'}} value={values.username} placeholder={t('cameras.login')} onChange={(event) => {onFieldChange('username', event.target.value)}}/>
                            {errors.username && <Error>{t('form.emptyFieldError')}</Error>}
                            <input className={classNames('popup-input', {'error-input': errors.password})} value={values.password} placeholder={t('cameras.password')} onChange={(event) => {onFieldChange('password', event.target.value)}}/>
                            {errors.password && <Error>{t('form.emptyFieldError')}</Error>}
                        </div>
                        </>
                    )
                }
            </div>

            {
                !properUrl &&
                (
                    <div className={'createNewCameraFooter'}>
                        <div className="selectCameraWrapperFooterRight" onClick={onUrlCheck}>
                            {
                                loadingUrl ?
                                <Spinner /> :
                                t('cameras.checkUrl')
                            }
                        </div>
                    </div>
                )
            }

            {
                properUrl &&
                (
                    <div className={'createNewCameraFooter'}>
                        <div className="selectCameraWrapperFooterRight" onClick={onSubmit}>
                            {
                                loading ?
                                <Spinner /> :
                                t('cameras.create')
                            }
                        </div>
                    </div>
                )
            }
        </div>
    )
}