import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'
import { ReactComponent as MaximizeIcon } from '../../assets/icons/maximize.svg'
import { ReactComponent as EllipseIcon } from '../../assets/icons/ellipse.svg'
import dotsIcon from '../../assets/icons/dotsVertical.svg'
import { EVENT_TYPES } from '../../utils/constants';
import { SCHEDULE_TYPES } from '../../utils/constants';
import { Menu } from '../menu/menu';

import styles from './rules-table.module.css'

export const RulesTable = ({data, deleteMethod, ...props}) => {
  const {t} = useTranslation("common")

  const [menuOpen, setMenuOpen] = useState(new Array(data?.length || 0).fill(false))

  const eventTypes = Object.entries(EVENT_TYPES).map(([value, caption]) => {
    return {
        caption: t(caption),
        value
    }
  })

  return (
      <>
      <table className={styles['rule-table']}>
        <tr>
          <th className={styles['heading']}>{t('automation.table.name')}</th>
          <th className={classNames(styles['heading'], styles['middle-cell-align'])}>{t('automation.table.status')}</th>
          <th className={classNames(styles['heading'], styles['middle-cell-align'])}>{t('automation.table.type')}</th>
          <th className={styles['heading']}>{t('automation.table.parameter')}</th>
        </tr>
        {
          data?.map((rule, index) => {
            let type, parameter
            let property
            try {
              property = JSON.parse(rule.property)
              type = property.type
              parameter = property.parameter
            } catch {
              property = rule.property
              type = ''
              parameter = ''
            }

            let scheduleParameter = ''

            if (type === 'schedule') {
              scheduleParameter = parameter
            }
           return (
            <tr>
              <td className={classNames(styles['left-cell'])}>{rule.name}</td>
              <td className={classNames(styles['middle-cell'], styles['middle-cell-align'])}>
                {
                  <EllipseIcon fill={rule.is_on ? "var(--color-status-green)" : "var(--color-status-red)"}/>
                }
              </td>
              <td className={classNames(styles['middle-cell'], styles['middle-cell-align'])}>
                {
                  type === 'schedule' || rule.type === 'schedule' ?
                  <CalendarIcon stroke={"var(--color-icon-stroke)"}/> :
                  <MaximizeIcon stroke={"var(--color-icon-stroke)"}/>
                }
              </td>
              <td className={classNames(styles['middle-cell'])}>{scheduleParameter.length > 0 ? t(SCHEDULE_TYPES[scheduleParameter]) + ' | ' + property.time : (parameter ? t(EVENT_TYPES[parameter]) : t(EVENT_TYPES[rule.property]))}</td>
              <td className={classNames(styles['right-cell'])}>
                <button className={styles['options-button']} onClick={() => {console.log(menuOpen); setMenuOpen(prev => {const newOpen = [...prev]; newOpen[index] = !newOpen[index]; return newOpen})}}>
                  <img src={dotsIcon} alt="" />
                </button>
                {
                  menuOpen[index] &&
                  <Menu items={[{
                    caption: 'Delete',
                    onClick: rule.onDelete
                  }]}/>
                }
              </td>
            </tr>
           ) 
          })
        }
      </table>
      </>
  );
}
