import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'
import { ReactComponent as MaximizeIcon } from '../../assets/icons/maximize.svg'
import { ReactComponent as EllipseIcon } from '../../assets/icons/ellipse.svg'
import dotsIcon from '../../assets/icons/dotsVertical.svg'
import { LAYER_TYPES } from '../../utils/constants';
import { Menu } from '../menu/menu';
import { Select } from '../select/select';
import { ToggleSwitch } from '../toggle/toggle';

import saveIcon from '../../assets/icons/save.svg'
import pencilIcon from '../../assets/icons/pencil.svg'
import xIcon from '../../assets/icons/close.svg'
import styles from './automation.module.css'

export const ZoneLayerTable = ({data, deleteMethod, onSave, currentPolygon, onCurrentChange, hideCurrent=false, onHideCurrent, ...props}) => {
  const {t} = useTranslation("common")

  const [menuOpen, setMenuOpen] = useState(new Array(data?.length || 0).fill(false))

  const [values, setValues] = useState([...data])

  const layerTypes = Object.entries(LAYER_TYPES).map(([value, caption]) => {
    return {
        caption: t(caption),
        value
    }
  })

  const saveLayer = (index) => {
    onSave(index, values)
  }

  return (
      <>
      <table className={styles['zone-table']}>
        <tr>
          <th className={styles['zone-cell']}>{t('automation.zone.name')}</th>
          <th className={styles['zone-cell']}>{t('automation.zone.type')}</th>
          <th className={styles['zone-cell']}>{t('automation.zone.status')}</th>
          <th className={styles['zone-cell']}></th>
        </tr>
        {
          [...(data || [])].map((layer, index) => {
           return (
            <tr>
              <td className={classNames(styles['zone-cell'])}>{t('automation.zone') + ' ' + String(index + 1)}</td>
              <td className={classNames(styles['zone-cell'], styles['type-cell'])}>
                <div className="inputWrapper">
                  {
                    t(LAYER_TYPES[layer.type])
                  }
                </div>
              </td>
              <td className={classNames(styles['zone-cell'])} align="center">
                <ToggleSwitch checked={layer.status === 'Enabled'} onChange={(e) => {setValues(prev => {console.log(prev); const newPrev = [...prev]; newPrev[index].status = newPrev[index].status === 'Enabled' ? 'Disabled' : 'Enabled'; return newPrev })}} disabled={true}/>
              </td>
              <td className={classNames(styles['zone-cell'])}>
                <div className={styles['button-container']}>
                </div>
              </td>
            </tr>
           )
           
           
          })
        }
        {
          !hideCurrent &&
          <tr>
            <td className={classNames(styles['zone-cell'])}>{t('automation.zone') + ' ' + String(data.length + 1)}</td>
            <td className={classNames(styles['zone-cell'], styles['type-cell'])}>
                <div className="inputWrapper">
                  {
                    <Select big={true} defaultValue={currentPolygon.type} items={layerTypes} onChange={(value) => onCurrentChange(prev => {return {...prev, type: value}})}/>
                  }
                </div>
              </td>
              <td className={classNames(styles['zone-cell'])} align="center">
                <ToggleSwitch checked={currentPolygon.status === 'Enabled'} onChange={(e) => {onCurrentChange(prev => {const newPrev = {...prev}; newPrev.status = newPrev.status === 'Enabled' ? 'Disabled' : 'Enabled'; return newPrev })}} disabled={false}/>
              </td>
              <td className={classNames(styles['zone-cell'])}>
                <div className={styles['button-container']}>
                      <button className={styles['green-button']} onClick={() => {saveLayer(); onHideCurrent(true)}}>
                          <img src={saveIcon} alt="" />
                      </button>
                      <button className={styles['base-button']} onClick={() => {onHideCurrent(true)}}>
                          <img src={xIcon} alt="" />
                      </button>
                </div>
              </td>
          </tr>
        }
      </table>
      </>
  );
}
