import { useEffect, useState } from 'react';
import styles from './main-sidebar-item.module.css'
import { Link } from 'react-router-dom';
import classNames from 'classnames'

export const MainSidebarItem = ({label, url, activeIconSrc, disabledIconSrc, active}) => {

  return (
    <div className={styles['sidebar-item']}>
      <Link to={url} className={classNames(styles['sidebar-item'], active ? styles.active : styles.disabled)}>
        {
          active ?
          activeIconSrc :
          disabledIconSrc
        }
        {label}
      </Link>
    </div>
  );
}
