import { useState, useCallback, useEffect, useRef } from "react";
import { Button } from "../../components/button/button";
import {ReactComponent as PictureIcon} from '../../assets/icons/picture.svg'



import background from '../../assets/image/default2.png'
import styles from './automation.module.css'

import '../../assets/css/view.css'

export const Vision = () => {
    const [points, setPoints] = useState([]);
    const [polygons, setPolygons] = useState([])
    
    const addPolygon = () => {
        setPolygons([...polygons, points])
        setPoints([])
    }
    const addPoint = useCallback(e => {
      const bounds = e.target.getBoundingClientRect();
      const clickedX = e.clientX - bounds.left;
      const clickedY = e.clientY - bounds.top;
      
      const newPoint = clickedX + "," + clickedY;
      setPoints([...points, newPoint]);
      
    }, [setPoints, points]);

    const [selectedFile, setSelectedFile] = useState()
    const [preview, setPreview] = useState()

    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }

        setSelectedFile(e.target.files[0])
    }

    const fileRef = useRef()
    
    return (
      <div>
        <p>Coordinates: {JSON.stringify(points)}</p>
        <div className="inputWrapper">
              <label htmlFor="file" className={'popup-input'}>
                  <PictureIcon stroke='var(--color-icon-stroke)'/>
                  {selectedFile.length > 0 ? selectedFile : 'Upload file'}
                  <input hidden ref={fileRef} id={"file"} className={'popup-input'} type="file" onChange={onSelectFile}/>
              </label>
          </div>

        <Button className={styles['add-layer-button']} onClick={addPolygon}>Add layer</Button>
        <br />
        <svg className={"canvas"} onClick={addPoint} style={{ backgroundImage: `url(${selectedFile})`, backgroundSize: '100% auto' }}>
            {polygons.length > 0 ? 
                polygons.map(polygon => <polygon points={polygon.join(" ")} />)
            : null}
            {points.length > 0 ? <polygon points={points.join(" ")} /> : null}
        </svg>
      </div>
    );
  };