import styles from './select.module.css'
import { useState } from 'react';
import classNames from 'classnames';
import {ReactComponent as CheckIcon} from '../../assets/icons/check.svg'
import {ReactComponent as DownIcon} from '../../assets/icons/down.svg'
import '../../assets/css/view.css';

export const Select = ({big = false, defaultValue, items, onChange, className, ...props}) => {
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState(items.find(item => item.value === defaultValue).caption)

    return (
        <>
            <div className={big ? styles['big-select'] : styles['custom-select']} onClick={()=>{setOpen(prev => !prev)}} {...props}>
                <span>{value}</span>
                <DownIcon stroke={'var(--color-input-border)'}/>
            </div>
            <div className={styles['container']} {...props}>
            {
                open && (
                    <div className={classNames(styles['options-container'], big ? '' : styles['small'])}>
                        {
                            open && items.map(item =>
                                <div className={styles['option-case']} onClick={() => {setValue(item.caption); onChange(item.value); setOpen(false)}}>{item.caption} {value === item.caption ? <CheckIcon /> : null}</div>
                            )
                        }
                    </div>
                )
            }
            </div>
        </>
    );
}
