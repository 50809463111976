import { setUser, deleteUser } from "../features/user/userSlice";
import { store } from "../store";

import http from "../utils/http-client";

const login = (data) => {
    return http.post('/user/login', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            store.dispatch(setUser(parsed.data))
            return parsed;
        }]
    });
}

const register = (data) => {
    return http.post('company/registration', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            console.log(parsed)
            return parsed;
        }]
    });
}

const getNotifications = () => {
    const data = {
        "limit":100,
        "offset":0
    }
    return http.post('/notification/get-list', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result).data;
            localStorage.setItem('notifications', JSON.stringify(parsed));
            return parsed;
        }]
    }).catch((err) => {localStorage.setItem('dataError', JSON.stringify(err));});
}

const createNotification = (data) => {
    const user = getAuthUser()
    return http.post('/notification/create', {'user_id': 1, 'company_id': user['company_id'], ...data}, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            return parsed;
        }]
    });
}

const deleteNotification = (data) => {
    return http.post('/notification/delete', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            return parsed;
        }]
    });
}

const logout = () => {
    store.dispatch(deleteUser())
}

const profile = () => {
    return store.getState().user.user;
}

const getAuthUser = () => {
    return store.getState().user.user;
}

const methods = {
    login,
    profile,
    getNotifications,
    createNotification,
    deleteNotification,
    logout,
    getAuthUser,
    register
}

export default methods;
