// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle_toggle__ySr0l.toggle_react-toggle--checked__4XOti .toggle_react-toggle-track__yv4J1 {
    background-color: var(--color-item-highlight)
}

.toggle_toggle-container__0WwVJ {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    color: var(--color-secondary-typography)
}`, "",{"version":3,"sources":["webpack://./src/components/toggle/toggle.module.css"],"names":[],"mappings":"AAAA;IACI;AACJ;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,2BAA2B;IAC3B,QAAQ;IACR;AACJ","sourcesContent":[".toggle.react-toggle--checked .react-toggle-track {\n    background-color: var(--color-item-highlight)\n}\n\n.toggle-container {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: flex-start;\n    gap: 5px;\n    color: var(--color-secondary-typography)\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle": `toggle_toggle__ySr0l`,
	"react-toggle--checked": `toggle_react-toggle--checked__4XOti`,
	"react-toggle-track": `toggle_react-toggle-track__yv4J1`,
	"toggle-container": `toggle_toggle-container__0WwVJ`
};
export default ___CSS_LOADER_EXPORT___;
