import classNames from 'classnames';

import styles from './search.module.css'
import searchIcon from '../../assets/icons/search.svg'

export const Search = ({clearIcon=false, bordered=false, ...props}) => {

  return (
    <div className={styles['search-container']}>
        <input className={bordered ? styles['search-bar-bordered'] : styles['search-bar']} type={"text"} {...props} />
        <button className={clearIcon ? styles['search-button-clear'] : styles['search-button']}>
          <img  src={searchIcon} alt="" />
        </button>
    </div>
  );
}
