import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    rules: {},
}

// {'scenarioId' : [rules]}

export const ruleSlice = createSlice({
    name: 'rules',
    initialState,
    reducers: {
        setRules: (state, action) => {
            state.rules[action.payload.id] = action.payload.data
        },
        addRule: (state, action) => {
            console.log(action.payload)
            state.rules[action.payload.id].push(action.payload.data)
        },
        updateSingleRule: (state, action) => {
            const ruleToUpdateIndex = state.rules[action.payload.id].findIndex(rule => rule.id === action.payload.data.id)
            state.rules[action.payload.id][ruleToUpdateIndex] = action.payload.data
        },
        removeRule: (state, action) => {
            const ruleToDeleteIndex = state.rules[action.payload.id].findIndex(rule => rule.id === action.payload.data.id)
            console.log(action.payload)
            console.log(ruleToDeleteIndex)
            state.rules[action.payload.id].splice(ruleToDeleteIndex, 1)
        },
        deleteRules: (state) => {
            state.rules = []
        },
    },
})

export const { setRules, addRule, updateSingleRule, removeRule, deleteRules } = ruleSlice.actions

export default ruleSlice.reducer