import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    notifications: [],
}

export const notifiactionSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setNotifications: (state, action) => {
            state.notifications = action.payload
        },
        addNotification: (state, action) => {
            state.notifications.push(action.payload)
        },
        updateSingleNotification: (state, action) => {
            const notificationToUpdateIndex = state.notifications.findIndex(notifiaction => notifiaction.id === action.payload.id)
            state.notifications[notificationToUpdateIndex] = action.payload
        },
        removeNotification: (state, action) => {
            const notificationToDeleteIndex = state.notifications.findIndex(notifiaction => notifiaction.id === action.payload.id)
            state.notifications.splice(notificationToDeleteIndex, 1)
        },
        deleteNotifications: (state) => {
            state.notifications = []
        },
    },
})

export const { setNotifications, addNotification, updateSingleNotification, removeNotification, deleteNotifications } = notifiactionSlice.actions

export default notifiactionSlice.reducer