import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    screen: {
        width: 0,
        height: 0
    },
}

export const screenSlice = createSlice({
    name: 'screen',
    initialState,
    reducers: {
        setDimensions: (state, action) => {
            state.screen = action.payload
        },
        setWidth: (state, action) => {
            state.screen.width = action.payload
        },
        setHeight: (state, action) => {
            state.screen.height = action.payload
        },
    },
})

export const { setDimensions, setWidth, setHeight } = screenSlice.actions

export default screenSlice.reducer