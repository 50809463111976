import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import authService from '../services/auth.service';
import styles from './login.module.css'

import { AuthForm } from './auth-form/auth-form';

const LoginComponent = () => {

  return (
    <>
    <div className={styles['container']}>
      <AuthForm />
    </div>
    </>
  )
}

export default LoginComponent