import authService from './auth.service.js'
import { setRules, addRule, removeRule, updateSingleRule } from '../features/rules/ruleSlice.js'
import { store } from "../store.js";

import http from "../utils/http-client.js";

const getAuthUser = authService.getAuthUser

const getRules = (id) => {
    console.log('CHECK ID', id)
    const user = getAuthUser()
    const data = {
        "limit":100,
        "offset":0,
        "strict": [
            {
                "input": "status",
                "value": String(id)
            },
        ],
    }
    return http.post('/regulation-list-action/get-list', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            console.log(parsed)
            store.dispatch(setRules({id: id, data : parsed.data.list}))
            return parsed.data.list;
        }]
    }).catch((err) => {});
}

const getRule = (data) => {
    return http.post('/regulation-list-action/get', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result).data;
            return parsed;
        }]
    }).catch((err) => {});
}

const createRule = (data) => {
    return http.post('/regulation-list-action/create', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            console.log("CHECK CREATION", parsed)
            store.dispatch(addRule({id: data.status, data: parsed.data}))
            return parsed;
        }]
    });
}

const updateRule = (data) => {
    return http.post('/regulation-list-action/update', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            console.log(parsed)
            store.dispatch(updateSingleRule({id: data.status, data: parsed.data}))
            return parsed;
        }]
    });
}

const deleteRule = (data) => {
    return http.post('/regulation-list-action/delete', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            console.log("CHECK PARSED RULE", parsed)
            store.dispatch(removeRule({id: data.status, data: data}))
            console.log("2")
            return parsed;
        }]
    }).catch(e => {console.log(e)});
}

const methods = {
    getRules,
    getRule,
    createRule,
    updateRule,
    deleteRule,
}

export default methods;
