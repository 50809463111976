import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { DarkModeToggle } from '../../components/dark-mode-toggle/dark-mode-toggle';
import { MainSidebar } from '../../components/main-sidebar/main-sidebar';
import { ContentSidebar } from '../../components/content-sidebar/content-sidebar';
import { ContentItem } from '../../components/content-item/content-item';
import { MobileHeader } from '../../components/mobile-header/mobile-header';

import ContentSideNav from '../../components/content-side-nav/content-side-nav';
import ContentListItem from '../../components/content-list-item/content-list-item';
import { useEffect } from 'react';
import {useTranslation} from "react-i18next";
import { ReactComponent as BackIcon } from '../../assets/icons/left.svg'
import { Select } from '../../components/select/select';

import styles from './settings.module.css'


const Settings = () => {
  const [t, i18n] = useTranslation('common')
  const navigate = useNavigate()
  const currentSettingId = useParams().settingId

  const screen = useSelector((state) => state.screen.screen);

  const sidebarMainMenu = useRef(null)
  const [openSidebarMainMenu, setOpenSidebarMainMenu] = useState(screen.width > 1000 ? true : false);
  const [desktopOpenMainSidebarMenu, setDesktopOpenMainSidebarMenu] = useState(true)
  const [showSidebar, setShowSidebar] = useState(true)

  const languages = [
    {
      caption: t('settings.english'),
      value: 'en'
    },
    {
      caption: t('settings.russian'),
      value: 'ru'
    },
    {
      caption: t('settings.kazakh'),
      value: 'kz'
    },
  ]

  const settings = [
    {
      "value": 'interface',
      "name":t("settings.interface"),
    }
  ]

  useEffect(() => {
    if(!currentSettingId) {
      navigate('/settings/' + String(settings[0].value))
    }
  }, [])

  return (
    <>
    <div ref={sidebarMainMenu}>
      <MainSidebar show={screen.width < 1000 ? openSidebarMainMenu : desktopOpenMainSidebarMenu} active={'/settings'} onClose={() => {setOpenSidebarMainMenu(false)}}/>
    </div>
    <div className={styles['mainWrapper']}>
      {
        showSidebar &&
        (
          <ContentSidebar mobileSidebarOpen={() => {setOpenSidebarMainMenu(true)}} heading={t('settings.settings')} isButtonEnabled={false} onClose={() => {setShowSidebar(prev => !prev)}}>
            {
              settings.map(setting => (<ContentItem item={setting} index onClick={() => { if (screen.width < 1000) setShowSidebar(false); navigate('/settings/' + String(setting.value))}} active={currentSettingId === String(setting.value)} />))
            }
          </ContentSidebar>
        )
      }
      <div className={styles['rightBlock']}>
        {
            screen.width < 1000 &&
            (
              <MobileHeader heading={settings.find(setting => String(setting.value) === currentSettingId)?.name} leftIcon={<BackIcon />} leftOnclick={() => {setShowSidebar(prev => !prev)}}/>
            )
        }
        <div className={styles['content-container']}>
          {
            currentSettingId && (
              <>
                <p className={styles['settings-heading']}>
                    {t('settings.language')}
                </p>
                <Select defaultValue={i18n.language} items={languages} onChange={(value) => i18n.changeLanguage(value)}/>

                <p className={styles['settings-heading']}>
                    {t('settings.darkMode')}
                </p>
                <div>
                  <DarkModeToggle />
                </div>
              </>
            )
          }
        </div>
      </div>
    </div>
  </>
);
}



export default Settings;
