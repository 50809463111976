import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    scenarios: [],
}

export const scenarioSlice = createSlice({
    name: 'scenarios',
    initialState,
    reducers: {
        setScenarios: (state, action) => {
            state.scenarios = action.payload
        },
        addScenario: (state, action) => {
            state.scenarios.push(action.payload)
        },
        updateSingleScenario: (state, action) => {
            const scenarioToUpdateIndex = state.scenarios.findIndex(scenario => scenario.id === action.payload.id)
            state.scenarios[scenarioToUpdateIndex] = action.payload
        },
        removeScenario: (state, action) => {
            const scenarioToDeleteIndex = state.scenarios.findIndex(scenario => scenario.id === action.payload.id)
            state.scenarios.splice(scenarioToDeleteIndex, 1)
        },
        deleteScenarios: (state) => {
            state.scenarios = []
        },
    },
})

export const { setScenarios, addScenario, updateSingleScenario, removeScenario, deleteScenarios } = scenarioSlice.actions

export default scenarioSlice.reducer