import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    servers: [],
}

export const serverSlice = createSlice({
    name: 'servers',
    initialState,
    reducers: {
        setServers: (state, action) => {
            state.servers = action.payload
        },
        addServer: (state, action) => {
            state.servers.push(action.payload)
        },
        updateSingleServer: (state, action) => {
            const serverToUpdateIndex = state.servers.findIndex(server => server.id === action.payload.id)
            state.servers[serverToUpdateIndex] = action.payload
        },
        removeServer: (state, action) => {
            const serverToDeleteIndex = state.servers.findIndex(server => server.id === action.payload.id)
            state.servers.splice(serverToDeleteIndex, 1)
        },
        deleteServers: (state) => {
            state.servers = []
        },
    },
})

export const { setServers, addServer, updateSingleServer, removeServer, deleteServers } = serverSlice.actions

export default serverSlice.reducer