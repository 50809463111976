// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error_error__Waog\\+ {
    color: var(--color-error-message);
    font-weight: 400;
    font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/error/error.module.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".error {\n    color: var(--color-error-message);\n    font-weight: 400;\n    font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `error_error__Waog+`
};
export default ___CSS_LOADER_EXPORT___;
