import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    groups: [],
}

export const groupSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        setGroups: (state, action) => {
            state.groups = action.payload
        },
        addGroup: (state, action) => {
            state.groups.push(action.payload)
        },
        updateSingleGroup: (state, action) => {
            const groupToUpdateIndex = state.groups.findIndex(group => group.id === action.payload.id)
            state.groups[groupToUpdateIndex] = action.payload
        },
        removeGroup: (state, action) => {
            const groupToDeleteIndex = state.groups.findIndex(group => group.id === action.payload.id)
            state.groups.splice(groupToDeleteIndex, 1)
        },
        deleteGroups: (state) => {
            state.groups = []
        },
    },
})

export const { setGroups, addGroup, updateSingleGroup, removeGroup, deleteGroups } = groupSlice.actions

export default groupSlice.reducer