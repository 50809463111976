import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../assets/css/view.css';
import { useTranslation } from 'react-i18next';
import addImage from '../../assets/image/add.png';
import cameraImage from '../../assets/image/camera.png';
import defaultImage from '../../assets/image/default.png';
import cameraService from '../../services/cameras.service';
import { Error } from '../../components/error/error';
import { FormTextField } from '../../components/form-textfield/form-textfield';
import classNames from 'classnames';
import styles from './auth-form.module.css'
import { TextField } from '../textfield/textfield';
import * as Yup from 'yup'
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { Spinner } from '../spinner/spinner';

import authService from '../../services/auth.service'

export const AuthFormLogin = ({handleClose, onSubmit, ...props}) => {

    const {t} = useTranslation("common");
    const navigate = useNavigate();

    const [formFields, setFormFields] = useState(false)
    const [validation, setValidation] = useState({login: true, password: true})
    const [wrongLogin, setWrongLogin] = useState(false)

    const [loading, setLoading] = useState(false)

    const formSchema = Yup.object().shape({
        login: Yup.string().email().required(),
        password: Yup.string().required(),
    })

    const [values, setValues] = useState({
        login: '',
        password: '',
    })
    const [errors, setErrors] = useState({
        login: '',
        password: '',
    })

    const onFieldChange = useCallback((fieldName, value) => {
        console.log(fieldName, value)
        setValues((prev) => {
            prev[fieldName] = value
            console.log(prev)
            return {...prev}
        })
    }, [])

    const handleValidSubmit = useCallback(
        async (event) => {
          event.preventDefault()
    
          const isFormValid = await formSchema.isValid(values, {
            abortEarly: false,
          })
    
          if (isFormValid) {
            const payload = {
                "login": values.login,
                "password": values.password,
            }
            try {
                setLoading(true)
                const result = await authService.login(payload);
                console.log("CHECK RESULT", result)
                if (result.data.data) {
                    navigate('/views');
                } else {
                    setWrongLogin(true)
                    setLoading(false)
                }
            } catch (error) {
                console.log(JSON.stringify(error));
            }
          } else {
            formSchema.validate(values, { abortEarly: false }).catch((err) => {
              const errors = err.inner.reduce((acc, error) => {
                return {
                  ...acc,
                  [error.path]: true,
                }
              }, {})
    
              setErrors((prevErrors) => {
                return {
                    ...prevErrors,
                    ...errors
                }
              })
            })
          }
        },
        [values]
    )
    return (
        <>


            <div className="inputWrapper">
                <label htmlFor="cameraName">
                    {t('login.login')}
                </label>
                <FormTextField value={values.login} error={errors.login} onInput={useCallback((event) => {onFieldChange('login', event.target.value)},[onFieldChange])} />
                {errors.login && <Error>{t('form.emptyFieldError')}</Error>}
            </div>

            <div className="inputWrapper">
                <label htmlFor="cameraName">
                    {t('login.password')}
                </label>
                <FormTextField  type={'password'} error={errors.password} value={values.password} onInput={useCallback((event) => {onFieldChange('password', event.target.value)},[onFieldChange])} />
                {errors.password && <Error>{t('form.emptyFieldError')}</Error>}
            </div>

            <div className={styles['forgot-password']}>{t('login.forgotPassword')}</div>

            <button className={styles['form-button']} onClick={handleValidSubmit}>
                {
                    loading ?
                    <Spinner /> :
                    t('auth.login')
                }
            </button>
            {
                wrongLogin &&
                <span style={{color: 'var(--color-status-red)', fontSize: '12px'}}>{t('login.message')}</span>   
            }
        </>
    )
}