import { configureStore } from '@reduxjs/toolkit'
import userSlice from './features/user/userSlice'
import notifiactionSlice from './features/notifications/notificationSlice'
import cameraSlice from './features/cameras/cameraSlice'
import viewsSlice from './features/views/viewsSlice'
import companySlice from './features/companies/companySlice'
import groupSlice from './features/groups/groupSlice'
import roleSlice from './features/roles/roleSlice'
import serverSlice from './features/servers/serverSlice'
import screenSlice from './features/screen/screenSlice'
import themeSlice from './features/theme/themeSlice'
import scenarioSlice from './features/scenarios/scenarioSlice'
import ruleSlice from './features/rules/ruleSlice'

export const store = configureStore({
    reducer: {
        user: userSlice,
        notifications: notifiactionSlice,
        cameras: cameraSlice,
        companies: companySlice,
        groups: groupSlice,
        roles: roleSlice,
        servers: serverSlice,
        viewsSlice: viewsSlice,
        screen: screenSlice,
        theme: themeSlice,
        scenarios: scenarioSlice,
        rules: ruleSlice
    },
})
