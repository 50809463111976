

import styles from './mobile-header.module.css'

export const MobileHeader = ({heading, leftIcon, leftOnclick, rightIcon, rightOnclick, ...props}) => {
    return (
        <div className={styles['mobile-header']} {...props}>
            <button className={styles['mobile-button']} onClick={leftOnclick}>{leftIcon}</button>
            <div className={styles['mobile-header-heading']}>{heading}</div>
            <button className={styles['mobile-button']} onClick={rightOnclick}>{rightIcon}</button>
        </div>
    );
}
