import React, { useState, useRef, useEffect, useCallback } from 'react';
import '../../assets/css/view.css';
import { useTranslation } from 'react-i18next';
import addImage from '../../assets/image/add.png';
import cameraImage from '../../assets/image/camera.png';
import defaultImage from '../../assets/image/default.png';
import scenarioService from '../../services/scenario.service'
import ruleService from '../../services/rule.service'
import { Error } from '../error/error';
import { FormTextField } from '../form-textfield/form-textfield';
import classNames from 'classnames';
import { Select } from '../select/select';
import { SCHEDULE_TYPES } from '../../utils/constants';
import * as yup from 'yup'

export const AddScheduleRuleFormContent = ({scenarioId, handleClose, ruleName, ...props}) => {
    const {t} = useTranslation("common")
    const [formFields, setFormFields] = useState(false)
    const [validation, setValidation] = useState({name: true})

    const formSchema = yup.object().shape({
        scheduleType: yup.string().required(),
        time: yup.string()
    })

    const [values, setValues] = useState({
        scheduleType: 'daily',
        time: '08:00'
    })
    const [errors, setErrors] = useState({
        scheduleType: false,
        time: false
    })

    const onFieldChange = useCallback((fieldName, value) => {
        console.log(fieldName, value)
        setValues((prev) => {
            prev[fieldName] = value
            console.log(prev)
            return {...prev}
        })
    }, [])

    const scheduleTypes = Object.entries(SCHEDULE_TYPES).map(([value, caption]) => {
        return {
            caption: t(caption),
            value
        }
    })

    const onSubmit = useCallback(
        async (event) => {
          event.preventDefault()
    
          const isFormValid = await formSchema.isValid(values, {
            abortEarly: false,
          })
    
          if (isFormValid) {
            const payload = {
                "name": ruleName,
                "status": String(scenarioId),
                "property" : JSON.stringify({
                    type: 'schedule',
                    parameter: values.scheduleType,
                    time: values.time
                }),
                "is_on" : true
            }
            try {
                const result = await ruleService.createRule(payload);
                console.log(result)
                if (result.data) {
                    handleClose()
                }
            } catch (error) {
                console.log(JSON.stringify(error));
            }
          } else {
            formSchema.validate(values, { abortEarly: false }).catch((err) => {
              const errors = err.inner.reduce((acc, error) => {
                return {
                  ...acc,
                  [error.path]: true,
                }
              }, {})
    
              setErrors((prevErrors) => {
                return {
                    ...prevErrors,
                    ...errors
                }
              })
            })
          }
        },
        [values]
    )

    return (
        <>
                <div className="inputWrapper">
                    <label htmlFor="cameraName">
                        {t('automation.period')}
                    </label>
                    <Select big={true} defaultValue={"daily"} items={scheduleTypes} onChange={(value) => setValues(prev => {return {...prev, scheduleType: value}})}/>
                    {errors.scheduleType && <Error>{t('form.emptyFieldError')}</Error>}
                </div>
                <div className="inputWrapper">
                    <input type="time" className={classNames('popup-input', {'error-input': errors.time})} value={values.time} onInput={useCallback((event) => {console.log(event.target.value); onFieldChange('time', event.target.value)},[onFieldChange])}/>
                    {errors.time && <Error>{t('form.emptyFieldError')}</Error>}
                </div>

            <div className={'createNewCameraFooter'} style={{background: 'none', padding: '0px'}}>
                <div className="selectCameraWrapperFooterRight" onClick={onSubmit}>
                    {t('automation.create')}
                </div>
            </div>
        </>
    )
}