// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner_loader__SyDAO {
    width: 12px;
    height: 12px;
    border: 1px solid var(--color-sidebar-typography-active);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: spinner_rotation__OodvN 1s linear infinite;
    }

    @keyframes spinner_rotation__OodvN {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner_dark-loader__miCVV {
    border: 1px solid black;
}`, "",{"version":3,"sources":["webpack://./src/components/spinner/spinner.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,wDAAwD;IACxD,gCAAgC;IAChC,kBAAkB;IAClB,qBAAqB;IACrB,sBAAsB;IACtB,qDAAsC;IACtC;;IAEA;IACA;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".loader {\n    width: 12px;\n    height: 12px;\n    border: 1px solid var(--color-sidebar-typography-active);\n    border-bottom-color: transparent;\n    border-radius: 50%;\n    display: inline-block;\n    box-sizing: border-box;\n    animation: rotation 1s linear infinite;\n    }\n\n    @keyframes rotation {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n\n.dark-loader {\n    border: 1px solid black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `spinner_loader__SyDAO`,
	"rotation": `spinner_rotation__OodvN`,
	"dark-loader": `spinner_dark-loader__miCVV`
};
export default ___CSS_LOADER_EXPORT___;
