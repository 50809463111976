import authService from './auth.service.js'
import { setCameras, addCamera, removeCamera, updateSingleCamera } from "../features/cameras/cameraSlice";
import { store } from "../store";

import http from "../utils/http-client";

const getAuthUser = authService.getAuthUser

const getCameras = () => {
    const data = {
        "limit":100,
        "offset":0
    }
    return http.post('/camera/get-list', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result).data;
            if(parsed.result === false) {
                store.dispatch(setCameras([]))
                return []
            }
            store.dispatch(setCameras(parsed.list))
            return parsed.list;
        }]
    }).catch((err) => {});
}

const getCamera = (data) => {
    return http.post('/camera/get', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result).data;
            return parsed;
        }]
    }).catch((err) => {});
}

const createCamera = (data) => {
    const user = getAuthUser()
    return http.post('/camera/create', {...data, 'company_id': user['company_id']}, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            console.log(parsed)
            if(parsed.data.result !== false) {
                store.dispatch(addCamera(parsed.data))
            }
            return parsed;
        }]
    });
}

const checkCamera = (data) => {
    return http.post('/camera/dubl', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            console.log(parsed)
            return parsed;
        }]
    });
}

const updateCamera = (data) => {
    const user = getAuthUser()
    return http.post('/camera/update', {...data, 'company_id': user['company_id']}, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            store.dispatch(updateSingleCamera(parsed.data))
            return parsed;
        }]
    });
}

const deleteCamera = (data) => {
    const user = getAuthUser()
    return http.post('/camera/delete', {...data, 'company_id': user['company_id']}, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            store.dispatch(removeCamera(data))
            return parsed;
        }]
    });
}

const methods = {
    getCameras,
    getCamera,
    createCamera,
    updateCamera,
    deleteCamera,
    checkCamera,
}

export default methods;
