// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_button__4-vvU {
    outline: none;
    width: -moz-fit-content;
    width: fit-content;
}

.button_button__4-vvU:focus {
    outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/button/button.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".button {\n    outline: none;\n    width: fit-content;\n}\n\n.button:focus {\n    outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `button_button__4-vvU`
};
export default ___CSS_LOADER_EXPORT___;
