import authService from './auth.service.js'
import { setNotifications, addNotification, removeNotification, updateSingleNotification } from "../features/notifications/notificationSlice.js";
import { store } from "../store.js";

import http from "../utils/http-client.js";

const getAuthUser = authService.getAuthUser

const getNotifications = () => {
    const data = {
        "limit":100,
        "offset":0
    }
    return http.post('/notification/get-list', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            console.log("CHECK RESULTS", parsed)
            store.dispatch(setNotifications(parsed.data.list))
            return parsed.data.list;
        }]
    }).catch((err) => {});
}

const getNotification = (data) => {
    return http.post('/notification/get', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result).data;
            return parsed;
        }]
    }).catch((err) => {});
}

const createNotification = (data) => {
    const user = getAuthUser()
    return http.post('/notification/create', {'user_id': 1, 'company_id': user['company_id'], ...data}, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            store.dispatch(addNotification(parsed.data))
            return parsed;
        }]
    });
}

const updateNotification = (data) => {
    return http.post('/notification/update', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            console.log(parsed)
            store.dispatch(updateSingleNotification(parsed.data))
            return parsed;
        }]
    });
}

const deleteNotification = (data) => {
    return http.post('/notification/delete', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            store.dispatch(removeNotification(parsed.data))
            return parsed;
        }]
    });
}

const methods = {
    getNotifications,
    getNotification,
    createNotification,
    updateNotification,
    deleteNotification,
}

export default methods;
