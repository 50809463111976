import React, { useState, useRef, useEffect, useCallback } from 'react';
import '../../assets/css/view.css';
import { useTranslation } from 'react-i18next';
import addImage from '../../assets/image/add.png';
import cameraImage from '../../assets/image/camera.png';
import defaultImage from '../../assets/image/default.png';
import scenarioService from '../../services/scenario.service'
import { Error } from '../error/error';
import { FormTextField } from '../form-textfield/form-textfield';
import classNames from 'classnames';
import * as yup from 'yup'

export const AddScenarioFormContent = ({handleClose, ...props}) => {
    const {t} = useTranslation("common")
    const [formFields, setFormFields] = useState(false)
    const [validation, setValidation] = useState({name: true})

    const formSchema = yup.object().shape({
        name: yup.string().required(),
    })

    const [values, setValues] = useState({
        name: '',
    })
    const [errors, setErrors] = useState({
        name: false,
    })

    const onFieldChange = useCallback((fieldName, value) => {
        console.log(fieldName, value)
        setValues((prev) => {
            prev[fieldName] = value
            console.log(prev)
            return {...prev}
        })
    }, [])

    const onSubmit = useCallback(
        async (event) => {
          event.preventDefault()
    
          const isFormValid = await formSchema.isValid(values, {
            abortEarly: false,
          })
    
          if (isFormValid) {
            const payload = {
                "name": values.name,
            }
            try {
                const result = await scenarioService.createScenario(payload);
                console.log(result)
                if (result.data) {
                    handleClose()
                }
            } catch (error) {
                console.log(JSON.stringify(error));
            }
          } else {
            formSchema.validate(values, { abortEarly: false }).catch((err) => {
              const errors = err.inner.reduce((acc, error) => {
                return {
                  ...acc,
                  [error.path]: true,
                }
              }, {})
    
              setErrors((prevErrors) => {
                return {
                    ...prevErrors,
                    ...errors
                }
              })
            })
          }
        },
        [values]
    )

    return (
        <div className={'createNewCameraWrapper'}>

            <div className={'createNewScenarioBody'}>
                <span className={'createNewCameraBodyTitle'}>{t('automation.addScenario')}</span>

                <div className="inputWrapper">
                    <label htmlFor="cameraName">
                        {t('automation.scenarioName')}
                    </label>
                    <input className={classNames('popup-input', {'error-input': errors.name})} value={values.name} placeholder={t('automation.scenarioName')} onInput={useCallback((event) => {console.log(event.target.value); onFieldChange('name', event.target.value)},[onFieldChange])}/>
                    {errors.name && <Error>{t('form.emptyFieldError')}</Error>}
                </div>
            </div>

            <div className={'createNewCameraFooter'}>
                <div className="selectCameraWrapperFooterRight" onClick={onSubmit}>
                    {t('automation.create')}
                </div>
            </div>
        </div>
    )
}