import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuTabsAuth } from '../menu-tabs-auth/menu-tabs-auth';
import { AuthFormLogin } from './auth-form-login'
import { AuthFormRegister } from './auth-form-register'

import styles from './auth-form.module.css'
import authLogo from '../../assets/icons/authLogo.svg'
import googleIcon from '../../assets/icons/googleIcon.svg'
import vkIcon from '../../assets/icons/vkIcon.svg'
import yandexIcon from '../../assets/icons/yandexIcon.svg'

export const AuthForm = ({handleClose, onLoginSubmit, ...props}) => {
    const {t} = useTranslation("common")
    const [tab, setTab] = useState('login')

    const tabs = [
        {
            caption: t('auth.register'),
            value: 'register',
            onClick: () => {setTab('register')}
        },
        {
            caption: t('auth.login'),
            value: 'login',
            onClick: () => {setTab('login')}
        },
    ]

    return (
        <div className={styles['form-card']}>
            <img src={authLogo} alt="error" />
            <span>
                {t('auth.message')}
            </span>
            <MenuTabsAuth tabs={tabs} active={tab} />
            {
                tab === 'login' &&
                <div className={styles['tab-content-container']}>
                  <AuthFormLogin onSubmit={onLoginSubmit} />
                </div>
            }

            {
                tab === 'register' &&
                <div className={styles['tab-content-container']}>
                  <AuthFormRegister onFinish={() => setTab('login')} />
                </div>
            }
            <div className={styles['other-register']}>
                <div>{t('auth.otherAuth')}</div>
                <div className={styles['register-ways-container']}>
                    <button className={styles['register-icon']}>
                        <img src={googleIcon} alt="error" />
                    </button>
                    <button className={styles['register-icon']}>
                        <img src={vkIcon} alt="error" />
                    </button>
                    <button className={styles['register-icon']}>
                        <img src={yandexIcon} alt="error" />
                    </button>
                </div>
            </div>
        </div>
    )
}