import React, { useState, useRef, useEffect, useCallback } from 'react';
import '../../assets/css/view.css';
import { useTranslation } from 'react-i18next';
import addImage from '../../assets/image/add.png';
import cameraImage from '../../assets/image/camera.png';
import defaultImage from '../../assets/image/default.png';
import serverService from '../../services/servers.service';
import { Error } from '../error/error';
import classNames from 'classnames';
import { FormTextField } from '../form-textfield/form-textfield';
import * as yup from 'yup'

export const AddServerFormContent = ({handleClose, ...props}) => {
    const {t} = useTranslation("common")
    const [formFields, setFormFields] = useState(false)
    const [validation, setValidation] = useState({streamUrl: true, name: true, username: true, password: true, snapshotUrl: true})

    const formSchema = yup.object().shape({
        name: yup.string().required(),
        login: yup.string().required(),
        password: yup.string().required(),
        link: yup.string().url().required(),
        comment: yup.string().required(),
    })

    const [values, setValues] = useState({
        name: '',
        login: '',
        password: '',
        link: '',
        comment: '',
    })
    const [errors, setErrors] = useState({
        name: false,
        login: false,
        password: false,
        link: false,
        comment: false,
    })

    const onFieldChange = useCallback((fieldName, value) => {
        console.log(fieldName, value)
        setValues((prev) => {
            prev[fieldName] = value
            console.log(prev)
            return {...prev}
        })
    }, [])

    const onSubmit = useCallback(
        async (event) => {
          event.preventDefault()
    
          const isFormValid = await formSchema.isValid(values, {
            abortEarly: false,
          })
    
          if (isFormValid) {
            const payload = {
                "company_id":1,
                "link": values.link,
                "login": values.login,
                "comment": values.comment,
                "name": values.name,
                "password": values.password
            }
            try {
                const result = await serverService.createServer(payload);
                console.log(result)
                if (result.data) {
                    handleClose()
                }
            } catch (error) {
                console.log(JSON.stringify(error));
            }
          } else {
            formSchema.validate(values, { abortEarly: false }).catch((err) => {
              const errors = err.inner.reduce((acc, error) => {
                return {
                  ...acc,
                  [error.path]: true,
                }
              }, {})
    
              setErrors((prevErrors) => {
                return {
                    ...prevErrors,
                    ...errors
                }
              })
            })
          }
        },
        [values]
    )

    return (
        <div className={'createNewCameraWrapper'}>

            <div className={'createNewCameraBody'}>
                <span className={'createNewCameraBodyTitle'}>{t('servers.addNewServer')}</span>

                <div className="inputWrapper">
                    <label htmlFor="serverName">
                        {t('servers.name')}
                    </label>
                    <input className={classNames('popup-input', {'error-input': errors.name})} value={values.name} placeholder={t('servers.name')} onInput={useCallback((event) => {console.log(event.target.value); onFieldChange('name', event.target.value)},[onFieldChange])}/>
                    {errors.name && <Error>{t('form.emptyFieldError')}</Error>}
                </div>

                <div className="inputWrapper">
                    <div className={'labelWrapper'}>
                        <label htmlFor="link">
                            {t('servers.link')}
                        </label>
                        <svg className={'helpIcon'} width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.30283 5.74984C5.43997 5.35998 5.71066 5.03123 6.06697 4.82183C6.42327 4.61243 6.84218 4.53588 7.24951 4.60575C7.65684 4.67562 8.0263 4.88739 8.29245 5.20356C8.55861 5.51973 8.70427 5.91989 8.70366 6.33317C8.70366 7.49984 6.95366 8.08317 6.95366 8.08317M7.00033 10.4165H7.00616M12.8337 7.49984C12.8337 10.7215 10.222 13.3332 7.00033 13.3332C3.77866 13.3332 1.16699 10.7215 1.16699 7.49984C1.16699 4.27818 3.77866 1.6665 7.00033 1.6665C10.222 1.6665 12.8337 4.27818 12.8337 7.49984Z" stroke="#5D8EA4" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <input className={classNames('popup-input', {'error-input': errors.link})} value={values.link} placeholder={t('servers.link')} onChange={useCallback((event) => {onFieldChange('link', event.target.value)},[onFieldChange])}/>
                    {errors.link && <Error>{t('form.emptyFieldError')}</Error>}
                </div>

                <div className="inputWrapper">
                    <div className={'labelWrapper'}>
                        <label htmlFor="login">
                            {t('servers.comment')}
                        </label>
                        <svg className={'helpIcon'} width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.30283 5.74984C5.43997 5.35998 5.71066 5.03123 6.06697 4.82183C6.42327 4.61243 6.84218 4.53588 7.24951 4.60575C7.65684 4.67562 8.0263 4.88739 8.29245 5.20356C8.55861 5.51973 8.70427 5.91989 8.70366 6.33317C8.70366 7.49984 6.95366 8.08317 6.95366 8.08317M7.00033 10.4165H7.00616M12.8337 7.49984C12.8337 10.7215 10.222 13.3332 7.00033 13.3332C3.77866 13.3332 1.16699 10.7215 1.16699 7.49984C1.16699 4.27818 3.77866 1.6665 7.00033 1.6665C10.222 1.6665 12.8337 4.27818 12.8337 7.49984Z" stroke="#5D8EA4" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <input className={classNames('popup-input', {'error-input': errors.comment})} value={values.comment} placeholder={t('servers.comment')} onChange={useCallback((event) => {onFieldChange('comment', event.target.value)},[onFieldChange])}/>
                    {errors.comment && <Error>{t('form.emptyFieldError')}</Error>}
                </div>

                <div className="inputWrapper">
                    <input className={classNames('popup-input', {'error-input': errors.login})} style={{marginBottom:'10px'}} value={values.login} placeholder={t('servers.login')} onChange={useCallback((event) => {onFieldChange('login', event.target.value)},[onFieldChange])}/>
                    {errors.login && <Error>{t('form.emptyFieldError')}</Error>}
                    <input className={classNames('popup-input', {'error-input': errors.password})} value={values.password} placeholder={t('servers.password')} onChange={useCallback((event) => {onFieldChange('password', event.target.value)},[onFieldChange])}/>
                    {errors.password && <Error>{t('form.emptyFieldError')}</Error>}
                </div>
            </div>

            <div className={'createNewCameraFooter'}>
                <div className="selectCameraWrapperFooterRight" onClick={onSubmit}>
                    {t('cameras.create')}
                </div>
            </div>
        </div>
    )
}