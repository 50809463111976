import { useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Head from 'next/head';
import { useParams, useNavigate } from 'react-router-dom';
import { Button as CustomButton } from '../../components/button/button';
import { TextField } from '../../components/textfield/textfield';
import { MainSidebar } from '../../components/main-sidebar/main-sidebar';
import { ContentSidebar } from '../../components/content-sidebar/content-sidebar';
import { ContentItem } from '../../components/content-item/content-item';

import { Search } from '../../components/search/search';
import { Filters } from '../../components/filters/filters';

import ContentSideNav from '../../components/content-side-nav/content-side-nav';
import CameraListItem from '../../components/content-list-item/content-list-item';
import serverService from '../../services/servers.service'
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import {useTranslation} from "react-i18next";
import { MobileHeader } from '../../components/mobile-header/mobile-header';
import { EventCard } from '../../components/event-card/event-card';
import { MenuTabs } from '../../components/menu-tabs/menu-tabs';
import notificationService from '../../services/notification.service'
import gearIcon from '../../assets/icons/gearIcon.svg'

import { ReactComponent as BackIcon } from '../../assets/icons/left.svg'
import { ReactComponent as VideoIcon } from '../../assets/icons/videoIcon.svg'
import { ReactComponent as UsersIcon } from '../../assets/icons/users.svg'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'
import { ReactComponent as StopIcon } from '../../assets/icons/stop.svg'
import listIcon from '../../assets/icons/list.svg'
import starIcon from '../../assets/icons/star.svg'

import editIcon from '../../assets/icons/edit.svg'
import defaultImage from '../../assets/image/default3.png'

import styles from './events.module.css'


const Events = () => {
  const {t} = useTranslation("common")
  const navigate = useNavigate()
  const currentOptionId = useParams().optionId

  const [formOpen, setFormOpen] = useState(false);
  const handleOpen = () => setFormOpen(true);
  const handleClose = () => setFormOpen(false);

  const notifications = useSelector((state) => state.notifications.notifications);

  const events = [
    {
      name : 'Камера id 45',
      text : 'Camera ID 45',
      src: defaultImage,
      info_subject: 'Номер автомобиля 5 был изменён',
      created_at: '14:54:43 8 ноября 2023'
    }
  ]

  const options = [
    {
      "value": 'events',
      "name":t("events.eventScroll"),
      "icon": listIcon
    },
    {
      "value": 'favorites',
      "name":t("events.favorites"),
      "icon": starIcon
    }
  ]

  const currentOptionData = () => {
    if(currentOptionId) {
      return options.find(server => String(server.id) === currentOptionId)
    }
    return {}
  }

  const screen = useSelector((state) => state.screen.screen);

  const [urlChangeDisabled, setUrlChangeDisabled] = useState(true)
  const [serverUrl, setServerUrl] = useState('')

  const [value, setValue] = useState(0);

  const [eventTab, setEventTab] = useState('events')
  const [showSidebar, setShowSidebar] = useState(true)
  const [showMainSidebar, setShowMainSidebar] = useState(true)

  const sidebarMainMenu = useRef(null)
  const [openSidebarMainMenu, setOpenSidebarMainMenu] = useState(screen.width > 1000 ? true : false);
  const [desktopOpenMainSidebarMenu, setDesktopOpenMainSidebarMenu] = useState(true)

  const tabs = [
    {
      caption: t('events.allEvents'),
      value: 'events',
      icon: <CalendarIcon stroke={eventTab === 'events' ? "var(--color-content-highlight)" : "var(--color-icon-stroke)" }/>,
      onClick: () => { setEventTab('events')}
    },
    {
      caption: t('events.allCameras'),
      value: 'cameras',
      icon: <VideoIcon stroke={eventTab === 'cameras' ? "var(--color-content-highlight)" : "var(--color-icon-stroke)" }/>,
      onClick: () => {setEventTab('cameras')}
    },
    {
      caption: t('events.allPeople'),
      value: 'people',
      icon: <UsersIcon stroke={eventTab === 'people' ? "var(--color-content-highlight)" : "var(--color-icon-stroke)" }/>,
      onClick: () => {setEventTab('people')}
    },
    {
      caption: t('events.allTags'),
      value: 'tags',
      icon: <StopIcon stroke={eventTab === 'tags' ? "var(--color-content-highlight)" : "var(--color-icon-stroke)" }/>,
      onClick: () => {setEventTab('tags')}
    },
  ]

  const makeFavorite = (notification) => {
    notificationService.updateNotification({...notification, is_favourites: !notification?.is_favourites})
  }

  const filteredNotifications = useMemo(
    () => {
      return notifications?.filter(notification => currentOptionId === 'favorites' ? notification?.is_favourites : true)
    }, [notifications, currentOptionId]
  )

  useEffect(() => {
    notificationService.getNotifications()
  }, [])

  return (
    <>
    <div ref={sidebarMainMenu}>
      <MainSidebar show={screen.width < 1000 ? openSidebarMainMenu : desktopOpenMainSidebarMenu} active={'/events'} onClose={() => {setOpenSidebarMainMenu(false)}}/>
    </div>
    <div className={styles['mainWrapper']}>
      {
          showSidebar &&
          (
            <ContentSidebar heading={t('events.events')} mobileSidebarOpen={() => {setOpenSidebarMainMenu(true)}} isButtonEnabled={true} buttonIcon={gearIcon} onClose={() => {setShowSidebar(prev => !prev)}}>
              {
                options.map((option) => (<ContentItem item={option} index onClick={() => { if (screen.width < 1000) setShowSidebar(false); navigate('/events/' + String(option.value))}} active={currentOptionId === String(option.value)} mainIcon={option.icon} />))
              }
            </ContentSidebar>
          )
      }
      <div className={styles['rightBlock']}>
        {
            screen.width < 1000 &&
            (
              <MobileHeader heading={t('events.events')} leftIcon={<BackIcon />} leftOnclick={() => {setShowSidebar(prev => !prev)}}/>
            )
        }
        <div className={styles['content-container']}>
          <div className={styles['notification-container']}>
            {
              filteredNotifications.map(notification => <EventCard {...notification} chosen={notification?.is_favourites} onStarClick={() => {makeFavorite(notification)}}/>)
            }
          </div>
        </div>
      </div>
    </div>
  </>
);
}



export default Events;
