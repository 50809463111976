import authService from './auth.service.js'
import { setServers, addServer, removeServer, updateSingleServer } from "../features/servers/serverSlice";
import { store } from "../store";

import http from "../utils/http-client";

const getAuthUser = authService.getAuthUser

const getServers = () => {
    const data = {
        "limit":100,
        "offset":0
    }
    return http.post('/server/get-list', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result).data;
            store.dispatch(setServers(parsed.list))
            return parsed;
        }]
    }).catch((err) => {});
}

const getServer = (data) => {
    return http.post('/server/get', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result).data;
            return parsed;
        }]
    }).catch((err) => {});
}

const createServer = (data) => {
    const user = getAuthUser()
    return http.post('/server/create', {'company_id': user['company_id'], ...data}, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            store.dispatch(addServer(parsed.data))
            return parsed;
        }]
    });
}

const updateServer = (data) => {
    return http.post('/server/update', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            store.dispatch(updateSingleServer(parsed.data))
            return parsed;
        }]
    });
}

const deleteServer = (data) => {
    return http.post('/server/delete', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            store.dispatch(removeServer(parsed.data))
            return parsed;
        }]
    });
}

const methods = {
    getServers,
    getServer,
    createServer,
    updateServer,
    deleteServer,
}

export default methods;
