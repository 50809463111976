import classNames from 'classnames';
import { Button as CustomButton } from '../button/button';
import { useTranslation } from 'react-i18next';

import styles from './menu-tabs-auth.module.css'

export const MenuTabsAuth = ({tabs, active, ...props}) => {
    return (
        <div className={styles['menu-tabs']} {...props}>
            {
                tabs.map(tab => <CustomButton caption={tab.caption} className={classNames(styles['tab-item'], active === tab.value ? styles['tab-item-active'] : '')} onClick={tab.onClick}>{tab.icon}{tab.caption}</CustomButton>)
            }
        </div>
    );
}
