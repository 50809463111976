import classNames from 'classnames';

import styles from './button.module.css'

export const Button = ({icon, onClick, children, ...props}) => {

  return (
    <button onClick={onClick} {...props}>
      {children}
    </button>
  );
}
