export const EVENT_TYPES = {
    'auto_num' : 'automation.autoNumber',
    'face_rec' : 'automation.faceRecognition',
    'face_id' : 'automation.faceIdentification',
    'fire' : 'automation.fire',
    'zone' : 'automation.zone'
}

export const SCHEDULE_TYPES = {
    'daily' : 'automation.scheduleDaily',
    'monthly' : 'automation.scheduleMonthly',
    'yearly' : 'automation.scheduleYearly',
    'hourly' : 'automation.scheduleHourly',
}

export const LAYER_TYPES = {
    'inclusive' : 'automation.inclusiveRule',
    'exclusive' : 'automation.exclusiveRule'
}