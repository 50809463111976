// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-header_mobile-header__j0Pct {
    width: 100%;
    background-color: var(--color-content);
    border-bottom: 1px solid var(--color-camera-popup);
    padding: 20px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mobile-header_mobile-header-heading__kW7X6 {
    font-size: 24px;
    font-weight: 700;
    color: var(--color-main-typography)
}

.mobile-header_mobile-button__p3keq {
    background: transparent;
    border: none;
    outline: none;
}

.mobile-header_mobile-button__p3keq:focus {
    outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/mobile-header/mobile-header.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,sCAAsC;IACtC,kDAAkD;IAClD,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB;AACJ;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".mobile-header {\n    width: 100%;\n    background-color: var(--color-content);\n    border-bottom: 1px solid var(--color-camera-popup);\n    padding: 20px 15px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.mobile-header-heading {\n    font-size: 24px;\n    font-weight: 700;\n    color: var(--color-main-typography)\n}\n\n.mobile-button {\n    background: transparent;\n    border: none;\n    outline: none;\n}\n\n.mobile-button:focus {\n    outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobile-header": `mobile-header_mobile-header__j0Pct`,
	"mobile-header-heading": `mobile-header_mobile-header-heading__kW7X6`,
	"mobile-button": `mobile-header_mobile-button__p3keq`
};
export default ___CSS_LOADER_EXPORT___;
