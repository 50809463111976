// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dark-mode-toggle_modes-container__XvAOW {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px
}

.dark-mode-toggle_mode-block__oGnKX {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color-content);
    border-radius: 5px;
    border: 1px solid var(--color-input-border);
    width: 100px;
    height: 50px;
}

.dark-mode-toggle_mode-block-active__NRCVG {
    border: 1px solid var(--color-content-highlight);
}

.dark-mode-toggle_icon__ehQHl {
    stroke: var(--color-icon-stroke)
}`, "",{"version":3,"sources":["webpack://./src/components/dark-mode-toggle/dark-mode-toggle.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,2BAA2B;IAC3B;AACJ;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,sCAAsC;IACtC,kBAAkB;IAClB,2CAA2C;IAC3C,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,gDAAgD;AACpD;;AAEA;IACI;AACJ","sourcesContent":[".modes-container {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    justify-content: flex-start;\n    gap: 10px\n}\n\n.mode-block {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    background-color: var(--color-content);\n    border-radius: 5px;\n    border: 1px solid var(--color-input-border);\n    width: 100px;\n    height: 50px;\n}\n\n.mode-block-active {\n    border: 1px solid var(--color-content-highlight);\n}\n\n.icon {\n    stroke: var(--color-icon-stroke)\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modes-container": `dark-mode-toggle_modes-container__XvAOW`,
	"mode-block": `dark-mode-toggle_mode-block__oGnKX`,
	"mode-block-active": `dark-mode-toggle_mode-block-active__NRCVG`,
	"icon": `dark-mode-toggle_icon__ehQHl`
};
export default ___CSS_LOADER_EXPORT___;
