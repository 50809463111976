import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    views: [],
}

export const viewsSlice = createSlice({
    name: 'views',
    initialState,
    reducers: {
        setViews: (state, action) => {
            state.views = action.payload
        },
        addView: (state, action) => {
            state.views.push(action.payload)
        },
        updateSingleView: (state, action) => {
            const cameraToUpdateIndex = state.views.findIndex(camera => camera.id === action.payload.id)
            state.views[cameraToUpdateIndex] = action.payload
        },
        removeView: (state, action) => {
            const cameraToDeleteIndex = state.views.findIndex(camera => camera.id === action.payload.id)
            state.views.splice(cameraToDeleteIndex, 1)
        },
        deleteViews: (state) => {
            state.views = []
        },
    },
})

export const { setViews, updateSingleView, addView, removeView, deleteViews } = viewsSlice.actions

export default viewsSlice.reducer
