import styles from './form-textfield.module.css'
import eyeIcon from '../../assets/icons/eye.svg'
import classNames from 'classnames'

import '../../assets/css/view.css'

import { memo, useCallback, useState } from 'react'

export const FormTextField = memo(({fieldName, labelText, type='text', error, ...props}) => {
  const [fieldType, setFieldType] = useState(type)

  console.log(fieldType)

  const showPassword = () => {
    if (fieldType === 'text') {
      setFieldType('password')
    } else {
      setFieldType('text')
    }
  }
  return (
    <fieldset className={styles['fieldset']}>
      <label htmlFor={fieldName}>{labelText}</label>

      <div className={styles['input-wrapper']}>
        <input
        className={classNames(styles['textfield'], {'error-input' : error})}
        type={fieldType}
        {...props}
        />

        {
          type === 'password' &&
          (
            <div src={eyeIcon} alt={"error"} onClick={showPassword} className={styles["field-icon"]}>
              <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.81509 9.60935C1.71295 9.44762 1.66188 9.36675 1.63329 9.24202C1.61182 9.14834 1.61182 9.00059 1.63329 8.9069C1.66188 8.78217 1.71295 8.70131 1.81509 8.53958C2.65915 7.20309 5.17155 3.82446 9.0003 3.82446C12.8291 3.82446 15.3415 7.20309 16.1855 8.53958C16.2877 8.70131 16.3387 8.78217 16.3673 8.9069C16.3888 9.00059 16.3888 9.14834 16.3673 9.24202C16.3387 9.36675 16.2877 9.44762 16.1855 9.60935C15.3415 10.9458 12.8291 14.3245 9.0003 14.3245C5.17155 14.3245 2.65915 10.9458 1.81509 9.60935Z" stroke="#5E5873" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.0003 11.3245C10.2429 11.3245 11.2503 10.3171 11.2503 9.07446C11.2503 7.83182 10.2429 6.82446 9.0003 6.82446C7.75766 6.82446 6.7503 7.83182 6.7503 9.07446C6.7503 10.3171 7.75766 11.3245 9.0003 11.3245Z" stroke="#5E5873" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          )

        }
      </div>
      
    </fieldset>
  )
})