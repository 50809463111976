import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { MobileHeader } from '../mobile-header/mobile-header';
import addImage from '../../assets/icons/add.svg';
import {ReactComponent as MenuIcon} from '../../assets/icons/menuMobile.svg'
import {ReactComponent as DotsHorizontal} from '../../assets/icons/dotsHorizontal.svg'

import { DropdownList } from '../dropdown-list/dropdown-list';

import styles from './content-sidebar.module.css';

export const ContentSidebar = ({mobileDropDownItems, mobileSidebarOpen, heading, isButtonEnabled, buttonIcon = addImage, onButtonClick=() => {}, onClose, children}) => {
    const [openMobileDropdown, setOpenMobileDropdown] = useState(false)

    const screen = useSelector((state) => state.screen.screen);
    
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
        width,
        height
        };
    }

    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    console.log('ITEMS', mobileDropDownItems)
    return (
        <div className={styles['leftBlock']}>

            {
                screen.width < 1000 &&
                (
                    <>
                    {openMobileDropdown &&
                    <div className={styles['dropdown-container']}><DropdownList items={mobileDropDownItems}/> </div>
                    }
                    <MobileHeader heading={heading} leftIcon={<MenuIcon />} rightIcon={<DotsHorizontal />} leftOnclick={mobileSidebarOpen} rightOnclick={()=>{setOpenMobileDropdown(prev => !prev)}}/>
                    </>
                    )
            }
            {
                screen.width > 1000 &&
                (
                    <div className={styles['leftBlockTop']}>
                        <h2>{heading}</h2>
                        {
                            isButtonEnabled &&
                            (
                                <button className={styles['addView']} onClick={onButtonClick}>
                                    <img className={styles['addViewIcon']} src={buttonIcon} alt=""/>
                                </button>
                            )   
                        }
                    </div>
                )
            }
            <div className={styles['content-contaner']}>
                <div className={styles['leftBlockList']}>
                    {children}
                </div>
            </div>
        </div>
    )
}
