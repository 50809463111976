import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    roles: [],
}

export const roleSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
        setRoles: (state, action) => {
            state.roles = action.payload
        },
        addRole: (state, action) => {
            state.roles.push(action.payload)
        },
        updateSingleRole: (state, action) => {
            const roleToUpdateIndex = state.roles.findIndex(role => role.id === action.payload.id)
            state.roles[roleToUpdateIndex] = action.payload
        },
        removeRole: (state, action) => {
            const roleToDeleteIndex = state.roles.findIndex(role => role.id === action.payload.id)
            state.roles.splice(roleToDeleteIndex, 1)
        },
        deleteRoles: (state) => {
            state.roles = []
        },
    },
})

export const { setRoles, addRole, updateSingleRole, removeRole, deleteRoles } = roleSlice.actions

export default roleSlice.reducer